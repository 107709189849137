import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { createSelector } from "reselect";

const AuthProtected = (props) => {

  const { roles } = props;

  const selectUserLogin = createSelector(
    (state: any) => state.Login,
    (Login) => ({
      user: Login.user
    })
  );
  const { user } = useSelector(selectUserLogin);

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login" }} />
    );
  }

  

  if (roles && roles.length > 0 && roles.indexOf(user.role) === -1) {
    return (
      <Navigate to={{ pathname: "/dashboard" }} />
    );
  }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AuthProtected;
