import React from "react";
import withRouter from "../../../Components/Common/withRouter";
import { Card, CardBody, CardTitle } from "reactstrap";

import { useTranslation } from "react-i18next";


const RequiredDocuments = () => {

  const translate = useTranslation();

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{translate.t("Required Documents")}</CardTitle>
        <p className="text-muted mb-4">{translate.t("All listed documents are required for processing any procedure at LFPNotary.")} </p>
        <p className="text-muted">
          <ul>
            <li className="mb-2">
              <strong>{translate.t("Scanned Identity Document")} :</strong> <br />
              {translate.t("A digital scan of your valid government-issued identification, such as a passport or driving licence.")}
            </li>
            <li className="mb-2">
              <strong>{translate.t("A Photo of You Holding the Printed Document")} :</strong> <br />
              {translate.t("This should be a clear photograph of you holding a printout of your identity document to help verify your identity.")}
            </li>
            {/* <li className="mb-2">
              <strong>{translate.t("A Photo of the Paper Impression of the Fingerprint Registered on the Document")} :</strong> <br />
              {translate.t("A clear photograph showing a paper impression of the fingerprint that's registered on the identity document, ensuring additional verification.")}
            </li> */}
            <li className="mb-2">
              <strong>{translate.t("Proof of Address (A utility bill clearly identifying your name and registered address)")} :</strong> <br />
              {translate.t("This can be a recent utility bill (like electricity, water, or internet), a bank statement, or any official document that clearly shows your name and address, serving as proof of residence.")}
            </li>
          </ul>

        </p>

      </CardBody>
    </Card>
  );
};

export default (withRouter(RequiredDocuments));
