import React from "react"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom";
import { OverviewTeamMember } from "../../../common/data";
import { useTranslation } from "react-i18next";

const Actions = () => {

  const translate = useTranslation();

  return (
    <Card>
      <CardBody>
        {/* <CardTitle className="mb-4">{translate.t("Assigned to")}</CardTitle> */}
        <div className="d-flex flex-wrap gap-2 justify-content-end">
          <Button
            color="primary"
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            {translate.t("Public")}
          </Button>
          
        </div>
      </CardBody>
    </Card>
  )
}


export default Actions;
