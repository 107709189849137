import { all, call, put, take, takeEvery, takeLatest, fork, actionChannel, cancelled, race } from 'redux-saga/effects'
import { END, buffers, eventChannel } from 'redux-saga'
import axios from 'axios';
import { getRequirements } from './thunk';


////ejecutar unicamente cuando este en la pagina de requerimientos


function requestChannel() {
    return eventChannel(emitter => {
        const iv = setInterval(() => {
            if (document.visibilityState === 'visible') {
                const authUser: any = window.localStorage.getItem("authUser");
                const authJWTUser = window.localStorage.getItem("authJWTUser");
                const secureSessionData = window.localStorage.getItem("secureSessionData");

                if (!authUser || !authJWTUser || !secureSessionData) {
                    emitter(END);
                }

                emitter("");
            }
        }, 1000);

        // The subscriber must return an unsubscribe function
        return () => {
            clearInterval(iv);
        }
    })
}


// // worker Saga: will be fired on USER_FETCH_REQUESTED actions
// function* validateSessionApi(channel) {
//     try {
//         const authUser = window.localStorage.authJWTUser;
//         const secureSessionData = window.localStorage.secureSessionData;

//         const user = yield axios.post('/auth/validate-session', {
//             session: secureSessionData
//         }, {
//             headers: {
//                 Authorization: `Bearer ${authUser}`
//             }
//         })

//         window.localStorage.setItem("authJWTUser", user.access_token);

//         yield put({ type: 'USER_FETCH_SUCCEEDED', user: user })
//         yield put(updateJwt(user.access_token));

//     } catch (e) {
//         channel.close();
//         yield fork(forceCloseSession);
//     }
// }




/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
export function* getRequirementsSaga(): any {
    const { payload } = yield take('INIT-GET-REQUIREMENTS');
    const chan = yield call(requestChannel)
    // console.log("INIT thread")

    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block

            const { logoutAction, requireOut, socketAction } = yield race({
                logoutAction: take('CLOSE-SESSION'),
                requireOut: take('STOP-GET-REQUIREMENTS'),
                socketAction: take(chan)
            });

            if (logoutAction || requireOut) {
                chan.close()
                // console.log("Stop thread")
                yield fork(getRequirementsSaga);
            } else {
                yield put(getRequirements({}))
            }
        }
    } finally {
        if (yield cancelled()) {
            chan.close()
        }

    }
}

// export function* forceCloseSessionSaga(): any {
//     yield takeEvery('FORCE-CLOSE-SESSION', forceCloseSession);
// }

