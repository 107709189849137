import React, { useCallback } from "react"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { downloadFileUser as onDownloadFileUser } from "slices/thunk";
import { createSelector } from "reselect";

const AttachedFiles = ({ files }) => {
  const translate = useTranslation();
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.files,
    (files) => ({
      error: files.error,
      loading: files.loading,
      filename: files.filename,
    })
  );

  const { loading, error, filename } = useSelector(selectProperties);

  const downloadFile = useCallback((filename: string) => {

    dispatch(onDownloadFileUser(filename));

  }, [])

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{translate.t("Attached Files")} </CardTitle>
        <div className="table-responsive">
          <Table className="table-nowrap align-middle table-hover mb-0">
            <tbody>
              {(files || [])?.map((file: any, i: number) => (
                <tr key={"_file_" + i}>
                  <td style={{ width: "45px" }}>
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-24">
                        <i className="bx bxs-file-doc" />
                      </span>
                    </div>
                  </td>
                  <td>
                    <h5 className="font-size-14 mb-1">
                      <Link to="#" className="text-dark">
                        {file.originalname}
                      </Link>
                    </h5>
                    <small>{translate.t("Date")} : {moment(file.creationdate).tz("Europe/Vilnius").format("YYYY-MM-DD HH:mm")}</small>
                  </td>
                  <td>
                    <div className="text-center">
                      {loading && filename === file.filename &&
                        <span><i className="bx bx-loader-alt h3 m-0 animation-loader" /></span>
                      }
                      {loading && filename != file.filename &&
                        <span className="text-secondary"><i className="bx bx-download h3 m-0" /></span>
                      }
                      {!loading &&
                        <Link to="#"  onClick={() => downloadFile(file.filename)} className="text-dark">
                          <i className="bx bx-download h3 m-0" />
                        </Link>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

export default AttachedFiles
