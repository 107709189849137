import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../slices/thunk";

import { reset } from "../../slices/auth/register/reducer";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/lfpnotary-favicon.png";
import logo from "../../assets/images/lfpnotary-favicon.png";
import { createSelector } from 'reselect';
import { useTranslation } from "react-i18next";
import { getLocalStorageAuth } from "../../helpers/auth_helper";


const Register = props => {


  const translate = useTranslation();
  const navigation = useNavigate();


  //meta title
  document.title = `${translate.t("LFPNotary")} | ${translate.t("Register")} `;

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(translate.t("Please Enter Your Name")),
      lastname: Yup.string().required(translate.t("Please Enter Your Last Name")),
      phone: Yup.string().required(translate.t("Please Enter Your Phone")),
      email: Yup.string().email("Invalid email").required(translate.t("Please Enter Your Email")),
      password: Yup.string().required(translate.t("Please Enter Your Password")).matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
        'Must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number'
      ),
    }),
    onSubmit: (values) => {
      dispatch(registerUser({
        user: values,
        history: navigation
      }));
    }
  });

  const selectProperties = createSelector(
    (state: any) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
    })
  );

  const { user, registrationError } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);


  useEffect(() => {
    return () => {
      dispatch(reset(""));
    }
  }, []);

  const selectPropertiesUser = createSelector(
    (state: any) => state.Login,
    (login) => ({
      userLogin: login.user
    })
  );

  const { userLogin } = useSelector(selectPropertiesUser);

  useEffect(() => {
    const { user:userjwt, jwt, secureSessionData } = getLocalStorageAuth();
    if (userLogin && userjwt && jwt && secureSessionData) {
      navigation('/dashboard')
    }
  }, [userLogin])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{translate.t("LFPNotary Register")} </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}


                      <Row className="mb-3">
                        <Col>
                          <Label className="form-label">{translate.t("Name")}</Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder={translate.t("Enter your name")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Label className="form-label">{translate.t("Last Name")}</Label>
                          <Input
                            name="lastname"
                            type="text"
                            placeholder={translate.t("Enter your lastname")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname && validation.errors.lastname ? true : false
                            }
                          />
                          {validation.touched.lastname && validation.errors.lastname ? (
                            <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>


                      <div className="mb-3">
                        <Label className="form-label">{translate.t("Phone")}</Label>
                        <Input
                          name="phone"
                          type="text"
                          placeholder={translate.t("Enter your phone")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">{translate.t("Email")}</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={translate.t("Enter email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">{translate.t("Password")}</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder={translate.t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {translate.t("Register")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          {translate.t("By registering you agree to the LFPNotary")}{" "}
                          <Link to="https://lfpnotary.com/privacy-policy/" className="text-primary">
                            {translate.t("Privacy Policy")}
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {translate.t("Already have an account ?")} {" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} LFPNotary.

                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
