import { createSlice } from "@reduxjs/toolkit";

import { getRequirements, getRequirementDetail, deleteRequirement } from "./thunk";

export const initialState = {
    requirements: [],
    paginationData: {},
    currentPage: 1,
    pageSize: 10,
    query: "",

    documentsUpload: [],
    requirementDetail: {},
    error: {},
    loading: true
};

const requirementsSlice = createSlice({
    name: 'RequirementsSlice',
    initialState,
    reducers: {
        setUploadFiles(state, action) {
            state.documentsUpload = state.documentsUpload.concat(action.payload);
        },
        setCountRequirements(state, action) {
            state.paginationData = action.payload;
        },
        resetUploadFiles(state, action) {
            state.documentsUpload = [];
        },
        removeUploadFile(state, action) {
            state.documentsUpload = state.documentsUpload.filter(document => document != action.payload);
        },
        setLocalPaginationData(state, action) {
            state.currentPage = action.payload.currentPage;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getRequirements.fulfilled, (state: any, action: any) => {
            state.requirements = action.payload;
            state.loading = true;
        });
        builder.addCase(getRequirements.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });
        builder.addCase(getRequirementDetail.fulfilled, (state: any, action: any) => {
            state.requirementDetail = action.payload;
        });

        builder.addCase(getRequirementDetail.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });


        builder.addCase(deleteRequirement.fulfilled, (state: any, action: any) => {
            state.requirements = state.requirements.filter(
                (project: any) => project.id !== action.payload
            );
        });

        builder.addCase(deleteRequirement.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});


export const {
    setUploadFiles,
    resetUploadFiles,
    removeUploadFile,
    setCountRequirements,
    setLocalPaginationData
} = requirementsSlice.actions

export default requirementsSlice.reducer;