import React from "react";
import { get } from "lodash";
import { Card, CardBody, Col, Row } from "reactstrap";
import img1 from "../../../assets/images/companies/img-1.png";
import lfpnotary from "../../../assets/images/users/lfpnotary.png";
import { useTranslation } from "react-i18next";

import { RequirementStatus } from "../components/status";
import { REQUIREMENT_DOCUMENTS } from "../../../helpers/data_helper";
import moment from "moment";

const ProjectDetail = ({ requirement }) => {
  const translate = useTranslation();

  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <img src={lfpnotary} alt="" className="avatar-sm me-4" />

          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15">{requirement.requirementtype}
            <span className="float-end">
              <RequirementStatus status={requirement.status} translate={translate} />
            </span>
            </h5>
            <p className="text-muted">{translate.t("London Financial Public Notary")}</p>
            
          </div>
        </div>

        <h5 className="font-size-15 mt-4">{translate.t("Requirement Details")} :</h5>

        <p className="text-muted" dangerouslySetInnerHTML={{__html: get(requirement, "description") }}>
          
        </p>

        <h5 className="font-size-15 mt-4">{translate.t("Required Documents")} :</h5>

        <p className="text-muted">
          <ul>
            {REQUIREMENT_DOCUMENTS.map(document =>
              <li>{document}</li>
            )}

          </ul>
        </p>

        {/* <div className="text-muted mt-4">
          {requirement &&
            (requirement.points || [])?.map((point: any, index: number) => (
              <p key={index}>
                <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                {point}
              </p>
            ))}
        </div> */}

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> {translate.t("Creation date")}
              </h5>
              <p className="text-muted mb-0">{moment(requirement.creationdate).tz("Europe/Vilnius").format("YYYY-MM-DD")}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            {requirement.aprovaldate &&
              <div className="mt-4">
                <h5 className="font-size-14">
                  <i className="bx bx-calendar-check me-1 text-primary" /> {translate.t("Approval date")}
                </h5>
                <p className="text-muted mb-0">{requirement.aprovaldate}</p>
              </div>
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};


export default ProjectDetail;
