import React, { useEffect, useState, useRef, useMemo, useCallback, useTransition } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Badge } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import TableContainer from "../../Components/Common/TableContainer";
import withRouter from "../../Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  deleteUsers as onDeleteUsers
}
  from "../../slices/users/thunk"
import { createSelector } from 'reselect';
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { USERS_ROLE, USERS_STATUS } from "../../helpers/data_helper";

import CreateFormModal from "./components/createFormModal";



const UserStatus = ({ status, translate }: any) => {

  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <Badge color="danger"> {translate.t(status)} </Badge>;
    case USERS_STATUS.REVIEWING:
      return <Badge color="warning"> {translate.t(status)} </Badge>;
    case USERS_STATUS.ACTIVE:
      return <Badge color="success"> {translate.t(status)} </Badge>;
    default:
      return <Badge color="success"> {translate.t(status)} </Badge>;
  }
};


const AdvisorList = () => {

  const translate = useTranslation();

  //meta title
  document.title = `${translate.t("Advisors")} | ${translate.t("LFPNotary")}`;

  const selectPropertiesUserLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      userLogin: login.user
    })
  );

  const { userLogin } = useSelector(selectPropertiesUserLogin);

  const selectProperties = createSelector(
    (state: any) => state.users,
    (users) => ({
      users: users?.users,
      loading: users.loading
    })
  );

  const { users, loading }: any = useSelector(selectProperties);

  const  [userData, setUserData ] =useState([]);


  useEffect(()=>{
    const userData = (users||[]).filter(user =>user.role !== USERS_ROLE.USER );
    setUserData(userData)
  },[users])
  

  const [isLoading, setLoading] = useState<boolean>(loading);

  const dispatch = useDispatch<any>();
  const [userSelected, setUserSelected] = useState<any>();

  const [modal, setModal] = useState<boolean>(false);
  

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);



  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <>
              {!cellProps.row.original.img ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">{cellProps.row.original.name.charAt(0)} </span>
                </div>
              ) : (
                <div>
                  <img className="rounded-circle avatar-xs" src={cellProps.row.original.img} alt="" />
                </div>
              )}
            </>
          )
        },
      },
      {
        header: translate.t("Name"),
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <>
              <h5 className='font-size-14 mb-1'>
                <Link to={`/user-profile/${cellProps.row.original.id}`} className='text-dark'>{`${cellProps.row.original.name} ${cellProps.row.original.lastname}`}</Link>
              </h5>
              <p className="text-muted mb-0">{cellProps.row.original.mail}</p>
            </>
          );
        },
      },
      {
        header: translate.t("Status"),
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <div>
              <UserStatus status={cellProps.getValue()} translate={translate} />
            </div>
          );
        },
      },
      {
        header: translate.t("Action"),
        accessorKey: 'action',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3">
              <Link to={`/user-profile/${cellProps.row.original.id}`} className="text-success" >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
              {userLogin.role === USERS_ROLE.ADMIN &&
                <Link to="#!" className="text-danger"
                  onClick={(event: any) => {
                    event.preventDefault();
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}  >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                </Link>
              }
            </div>
          );
        },
      },
    ],
    [userLogin]
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);



  var node: any = useRef();
  const onPaginationPageChange = (page: any) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (users: any) => {
    setUserSelected(users.id);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUsers(userSelected.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate.t("Users")} breadcrumbItem={translate.t("Advisors")} />
          <Row>
            <Col lg="12">
              {
                isLoading ? <Spinners setLoading={setLoading} />
                  :
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={userData || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        handleUserClick={handleUserClicks}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                        buttonName={translate.t("Add new user")}
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </CardBody>
                  </Card>
              }


              <CreateFormModal toggle={toggle} modal={modal} />

            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(AdvisorList);
