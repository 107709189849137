import React, { useEffect, useState, useRef, useMemo, useCallback, useTransition } from "react";
import { Link } from "react-router-dom";
import {
  Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, Label, FormFeedback, Input, Form, Button, Badge
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "../../../Components/Common/withRouter";

import { createSelector } from 'reselect';
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { USERS_STATUS, DOCUMENT_TYPES, HAYA_COUNTRIES } from "../../../helpers/data_helper";

import { registerUserFull } from "../../../slices/thunk";


const CreateFormModal = (props: any) => {

  const translate = useTranslation();

  const dispatch = useDispatch<any>();

  const { toggle, modal } = props;

  const document_types = Object.keys(DOCUMENT_TYPES).map(key => DOCUMENT_TYPES[key]);


  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      lastname: "",
      document: "",
      documenttype: "",
      nationality: "",
      phone: "",
      email: "",
      address: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(translate.t("Please Enter Your Name")),
      lastname: Yup.string().required(translate.t("Please Enter Your Last Name")),
      document: Yup.string().required(translate.t("Please Enter Your Document")),
      documenttype: Yup.string().required(translate.t("Please Enter Your Document Type")),
      nationality: Yup.string().required(translate.t("Please Enter Your Nationality")),
      phone: Yup.string().required(translate.t("Please Enter Your Phone")),
      email: Yup.string().required(translate.t("Please Enter Your Email")),
      address: Yup.string().required(translate.t("Please Enter Your Address")),
      password: Yup.string().required(translate.t("Please Enter Your Password")),

    }),
    onSubmit: (values: any) => {
      // const newUser = {
      //   id: Math.floor(Math.random() * (30 - 20)) + 20,
      //   name: values["name"],
      //   designation: values["designation"],
      //   email: values["email"],
      //   tags: values["tags"],
      //   projects: values["projects"],
      // };
      // // save new user
      // dispatch(onAddNewUser(newUser));

      dispatch(registerUserFull(values));

      validation.resetForm();


      toggle();
    },
  });

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4"> {translate.t("Add User")}</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Row>
                  <Col>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name ? true : false}
                    />
                    {validation.touched.name &&
                      validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col>
                    <Label>Last name</Label>
                    <Input
                      name="lastname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname && validation.errors.lastname ? true : false}
                    />
                    {validation.touched.lastname &&
                      validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

              </div>
              <div className="mb-3">
                <Row>
                  <Col md={4}>
                    <Label>Document type</Label>
                    <Input
                      type="select"
                      name="documenttype"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.documenttype || ''}
                      invalid={
                        validation.touched.documenttype && validation.errors.documenttype ? true : false}
                    >
                      <option>Select</option>
                      {document_types.map(documenttype =>
                        <option value={documenttype} >{translate.t(documenttype)}</option>
                      )}

                    </Input>
                    {validation.touched.documenttype && validation.errors.documenttype ?
                      (
                        <FormFeedback type="invalid">  {validation.errors.documenttype} </FormFeedback>
                      ) : null}
                  </Col>
                  <Col md={8}>
                    <Label>Document</Label>
                    <Input
                      name="document"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.document || ""}
                      invalid={
                        validation.touched.document && validation.errors.document ? true : false}
                    />
                    {validation.touched.document &&
                      validation.errors.document ? (
                      <FormFeedback type="invalid">
                        {validation.errors.document}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

              </div>
              <div className="mb-3">
                <Row>
                  <Col md={4}>
                    <Label>Nationality</Label>
                    <Input
                      type="select"
                      name="nationality"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.nationality || ''}
                      invalid={
                        validation.touched.nationality && validation.errors.nationality ? true : false}
                    >

                      <option>Select</option>
                      {HAYA_COUNTRIES
                        .map(country => translate.t(country))
                        .sort((a:any, b:any) => ('' + a).localeCompare(b))
                        .map(country => <option value={country}>{country}</option>)}

                    </Input>
                    {validation.touched.nationality && validation.errors.nationality ?
                      (
                        <FormFeedback type="invalid">  {validation.errors.nationality} </FormFeedback>
                      ) : null}
                  </Col>
                  <Col md={8}>
                    <Label>Phone</Label>
                    <Input
                      name="phone"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone ? true : false}
                    />
                    {validation.touched.phone &&
                      validation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

              </div>
              <div className="mb-3">
                <Label>Address</Label>
                <Input
                  name="address"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address ? true : false}
                />
                {validation.touched.address && validation.errors.address ?
                  (
                    <FormFeedback type="invalid">   {validation.errors.address} </FormFeedback>
                  ) : null}
              </div>
              <div className="mb-3">
                <Label>Email</Label>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email ? true : false}
                />
                {validation.touched.email && validation.errors.email ?
                  (
                    <FormFeedback type="invalid">   {validation.errors.email} </FormFeedback>
                  ) : null}
              </div>
              <div className="mb-3">
                <Label>Password</Label>
                <Input
                  name="password"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password ? true : false}
                />
                {validation.touched.password && validation.errors.password ?
                  (
                    <FormFeedback type="invalid">   {validation.errors.password} </FormFeedback>
                  ) : null}
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button type="submit" color="success" className="save-user"> {translate.t("Add")}  </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(CreateFormModal);
