import { all, call, put, take, takeEvery, takeLatest, fork, actionChannel, cancelled, race } from 'redux-saga/effects'
import { END, buffers, eventChannel } from 'redux-saga'
import axios from 'axios';

import { logoutUser } from "./thunk";
import { updateJwt } from "./auth/login/reducer";

import Push from 'push.js';
import { getRequirementsSaga } from './requirements/saga';


function validateSession() {
    return eventChannel(emitter => {
        let timeRenew = 0;
        let timeRenewNotification = 0;
        const intervaReview = 900000;//900000
        const deadline = 1300000;//900000
        const iv = setInterval(() => {

            let forceValidateSession = window.localStorage.getItem("forceValidateSession");
            

            if (document.visibilityState === 'visible' && timeRenew >= intervaReview) {
                const authUser: any = window.localStorage.getItem("authUser");
                const authJWTUser = window.localStorage.getItem("authJWTUser");
                const secureSessionData = window.localStorage.getItem("secureSessionData");
                window.localStorage.removeItem("forceValidateSession");

                if (!authUser || !authJWTUser || !secureSessionData) {
                    emitter(END);
                }

                emitter("")
                timeRenew = 0;
                timeRenewNotification = 0
            }

            if (document.visibilityState !== 'visible' || forceValidateSession === "true") {
                timeRenew = intervaReview;
            }

            let allowPush = window.localStorage.getItem("allowPush");

            if (timeRenewNotification != 1 && timeRenewNotification >= deadline && allowPush === "true" && document.visibilityState !== 'visible') {
                Push.create("LFPNOtary", {
                    body: "Tu sesión está a punto de expirar por seguridad.",
                    icon: 'https://lfpnotary.com/wp-content/uploads/2024/03/cropped-lfpnotary-favicon-192x192.png',
                    timeout: 8000
                });

                timeRenewNotification = -1;
            }


            timeRenewNotification += timeRenewNotification != -1 ? 1000 : 0;
            timeRenew += 1000;

        }, 1000);

        // The subscriber must return an unsubscribe function
        return () => {
            clearInterval(iv);
        }
    })
}


// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* validateSessionApi(channel) {
    try {
        const authUser = window.localStorage.authJWTUser;
        const secureSessionData = window.localStorage.secureSessionData;

        const user = yield axios.post('/auth/validate-session', {
            session: secureSessionData
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        })

        window.localStorage.setItem("authJWTUser", user.access_token);

        yield put({ type: 'USER_FETCH_SUCCEEDED', user: user })
        yield put(updateJwt(user.access_token));

    } catch (e) {
        channel.close();
        yield fork(forceCloseSession);
    }
}

function* forceCloseSession() {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("authJWTUser");
        localStorage.removeItem("secureSessionData");

        window.location.reload();

    } catch (e) {
       console.log(e);
    }
}



/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* validateSessionSaga(): any {
    const { payload } = yield take('INIT-SESSION');
    const chan = yield call(validateSession)

    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block

            const { logoutAction, socketAction } = yield race({
                logoutAction: take('CLOSE-SESSION'),
                socketAction: take(chan)
            });

            if (logoutAction) {
                chan.close()
                yield fork(validateSessionSaga);
                // yield take(END);
            } else {
                yield fork(validateSessionApi, chan)
            }
        }
    } finally {
        if (yield cancelled()) {
            chan.close()
        }

    }
}

function* forceCloseSessionSaga(): any {
    yield takeEvery('FORCE-CLOSE-SESSION', forceCloseSession);
}


export default function* rootSaga() {
    yield all([
        validateSessionSaga(),
        forceCloseSessionSaga(),
        getRequirementsSaga()
    ])
}