import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../Components/Common/withRouter";
import { Badge, Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap";

import TableContainer from "Components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";
// import { userProfile as user } from "common/data";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
// import ApexRevenue from "../ApexRevenue";

import AttachedFiles from "./attachedFiles";
import RequireDocuments from "./require-documents";
import PersonalInformation from "./personal-information";
import UserRequirements from "./user-requirements";

import Actions from "./actions";

import UploadDocuments from "./uploadDocuments";
import { useTranslation } from "react-i18next";
import { USERS_STATUS } from "../../../helpers/data_helper";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
// import {use} from "react-router-dom";


import {
  getUserById as onGetUserById,
  getLoginUserById as ongetLoginUserById,
  getRequirements as onGetRequirements
} from "slices/thunk";


import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const UserStatus = ({ status, translate }: any) => {

  // const history = use

  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <Badge color="danger"> {translate.t(status)} </Badge>;
    case USERS_STATUS.REVIEWING:
      return <Badge color="warning"> {translate.t(status)} </Badge>;
    case USERS_STATUS.ACTIVE:
      return <Badge color="success"> {translate.t(status)} </Badge>;
    default:
      return <Badge color="success"> {translate.t(status)} </Badge>;
  }
};


const UserProfile = (props: any) => {

  const translate = useTranslation();
  //meta title

  document.title = `${translate.t("Profile")} | ${translate.t("LFPNotary")}`;

  const { router, localuser, disableActions } = props;

  //////////////////
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.users,
    (users) => ({
      user: users.user,

    })
  );
  const { user } = useSelector(selectProperties);



  const selectPropertiesRequirements = createSelector(
    (state: any) => state.requirements,
    (requirements) => ({
      requirements: requirements.requirements,
      loading: requirements.loading
    })
  );

  const { requirements, loading } = useSelector(selectPropertiesRequirements);



  const params = props.router.params

  useEffect(() => {

  }, [dispatch]);

  useEffect(() => {

    // dispatch(onGetRequirements());

    if (params && params.id) {
      dispatch(onGetUserById(params.id));
    } if (localuser) {
      dispatch(ongetLoginUserById({
        history: router.navigate
      }));
    }


  }, [params, dispatch]);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

          <Row>
            <Col xl={4}>
              <PersonalInformation user={user} />
              <RequireDocuments />
            </Col>

            <Col xl={8}>

              {user.status !== USERS_STATUS.ACTIVE &&
                <Card>
                  <CardBody>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      <p className="m-0 text-danger">{translate.t("To make any request on LFPNotary, please upload all the required documents")}.</p>
                    </div>
                  </CardBody>
                </Card>
              }

              <Actions user={user} disableActions={disableActions} />
              <UploadDocuments user={user} />
              <AttachedFiles files={user.files || []} />

              <UserRequirements requirements={ requirements.filter(item => item.userid === user.id) } />

            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(UserProfile);

