import { Badge } from "reactstrap";
import { REQUEST_STATUS } from "../../../helpers/data_helper";

export const RequirementStatus = ({ status, translate }: any) => {

    switch (status) {
        case REQUEST_STATUS.REVIEWING:
            return <Badge color="warning"> {translate.t(status)} </Badge>;
        case REQUEST_STATUS.CLOSED:
            return <Badge color="danger"> {translate.t(status)} </Badge>;
        case REQUEST_STATUS.ACTIVE:
            return <Badge color="success"> {translate.t(status)} </Badge>;
        default:
            return <Badge color="success"> {translate.t(status)} </Badge>;
    }
};
