import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../Components/Common/withRouter";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";

import TableContainer from "Components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";
// import { userProfile as user } from "common/data";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
// import ApexRevenue from "../ApexRevenue";

import AttachedFiles from "./attachedFiles";
import RequireDocuments from "./require-documents";

import Actions from "./actions";

import UploadDocuments from "./uploadDocuments";
import { useTranslation } from "react-i18next";
import { USERS_STATUS, DOCUMENT_TYPES, HAYA_COUNTRIES } from "../../../helpers/data_helper";
import * as Yup from "yup";
import { useFormik } from "formik";




import {
  updateUser as onUpdateUser
}
  from "../../../slices/users/thunk"
import { useDispatch } from "react-redux";

const UserStatus = ({ status, translate }: any) => {

  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <Badge color="danger"> {translate.t(status)} </Badge>;
    case USERS_STATUS.REVIEWING:
      return <Badge color="warning"> {translate.t(status)} </Badge>;
    case USERS_STATUS.ACTIVE:
      return <Badge color="success"> {translate.t(status)} </Badge>;
    default:
      return <Badge color="success"> {translate.t(status)} </Badge>;
  }
};


const PersonalInformation = ({ user }) => {

  const translate = useTranslation();


  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const document_types = Object.keys(DOCUMENT_TYPES).map(key => DOCUMENT_TYPES[key]);


  const dispatch = useDispatch<any>();

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);











  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (user && user.name) || '',
      lastname: (user && user.lastname) || '',
      documenttype: (user && user.documenttype) || '',
      document: (user && user.document) || '',
      nationality: (user && user.nationality) || '',
      phone: (user && user.phone) || '',
      email: (user && user.mail) || '',
      address: (user && user.address) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      documenttype: Yup.string().required("Please Enter Your Document Type"),
      document: Yup.string().required("Please Enter Your Document"),
      nationality: Yup.string().required("Please Enter Your Nationality"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),

    }),
    onSubmit: (values: any) => {

      const updateUser = {
        id: user.id,
        name: values.name,
        lastname: values.lastname,
        documenttype: values.documenttype,
        document: values.document,
        nationality: values.nationality,
        phone: values.phone,
        email: values.email,
        address: values.address
      };
      // update user
      dispatch(onUpdateUser(updateUser));
      validation.resetForm();

      toggle();
    },
  });

  const handleUserClick = useCallback(() => {
    // const user = arg;

    // setContact({
    //   id: user.id,
    //   name: user.name,
    //   designation: user.designation,
    //   email: user.email,
    //   tags: user.tags,
    //   projects: user.projects,
    // });
    setIsEdit(true);
    validation.resetForm();

    toggle();
  }, [toggle]);

  return (
    <>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs={7}>
              <div className="text-primary p-3">
                <h5 className="text-primary">{user.name}</h5>
                <p>{translate.t("LFPNotary")}</p>
              </div>
            </Col>
            <Col xs={5} className="align-self-end">
              <img src={profile1} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row className="mb-4">
            <Col sm={12}>
              <Row>

                <Col sm={6}>
                  <div className="avatar-md profile-user-wid mb-1">

                    {!user.img ? (
                      <div className="avatar-md ">
                        <span
                          style={{
                            fontSize: "18pt",
                            border: "3px solid whitesmoke"
                          }}
                          className="avatar-title rounded-circle img-thumbnail">{(user.name || "").charAt(0)} </span>
                      </div>
                    ) : (
                      <img className="img-thumbnail rounded-circle" src={user.img} alt="" />
                    )}

                  </div>
                </Col>
                <Col sm={6}>
                  <div className="justify-content-end d-flex">
                    <div className="mt-4">
                      <Link to="#" className="btn btn-primary  btn-sm"
                        onClick={(event: any) => {
                          event.preventDefault();
                          // const userData = cellProps.row.original;
                          handleUserClick();
                        }}
                      > {translate.t("Edit")} <i className="mdi mdi-arrow-right ms-1" /></Link>


                    </div>
                  </div>
                </Col>
              </Row>


            </Col>

            <Col sm={12}>
              <h5 className="font-size-15 text-truncate">{`${user.name} ${user.lastname}`}</h5>
              <p className="text-muted mb-0 text-truncate">{user.mail} </p>

            </Col>

          </Row>
          <Row>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>


                  <tr>
                    <th scope="row">{translate.t("Status")} :</th>
                    <td><UserStatus status={user.status} translate={translate} /> </td>
                  </tr>
                  <tr>
                    <th scope="row">{translate.t("Document type")} :</th>
                    <td>{user.documenttype}</td>
                  </tr>
                  <tr>
                    <th scope="row">{translate.t("Document")} :</th>
                    <td>{user.document}</td>
                  </tr>
                  <tr>
                    <th scope="row">{translate.t("Nacionality")} :</th>
                    <td>{user.nationality}</td>
                  </tr>

                  <tr>
                    <th scope="row">{translate.t("Phone")} :</th>
                    <td>{user.phone}</td>
                  </tr>
                  <tr style={{ border: "transparent" }}>
                    <th style={{ paddingBottom: 0 }} scope="row">{translate.t("Address")} :</th>
                  </tr>
                  <tr>

                    <td>{user.address}</td>
                  </tr>

                </tbody>
              </Table>
            </div>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4"> {!!isEdit ? "Edit User" : "Add User"}</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <div className="mb-3">
                  <Row>
                    <Col>
                      <Label>Name</Label>
                      <Input
                        disabled={user.status === USERS_STATUS.ACTIVE}
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name &&
                        validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Label>Last name</Label>
                      <Input
                        disabled={user.status === USERS_STATUS.ACTIVE}
                        name="lastname"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastname || ""}
                        invalid={
                          validation.touched.lastname && validation.errors.lastname ? true : false}
                      />
                      {validation.touched.lastname &&
                        validation.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                </div>
                <div className="mb-3">
                  <Row>
                    <Col md={4}>
                      <Label>Document type</Label>
                      <Input
                        disabled={user.status === USERS_STATUS.ACTIVE}
                        type="select"
                        name="documenttype"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.documenttype || ''}
                        invalid={
                          validation.touched.documenttype && validation.errors.documenttype ? true : false}
                      >
                        <option>Select</option>
                        {document_types.map(documenttype =>
                          <option value={documenttype} >{translate.t(documenttype)}</option>
                        )}
                      </Input>
                      {validation.touched.documenttype && validation.errors.documenttype ?
                        (
                          <FormFeedback type="invalid">  {validation.errors.documenttype} </FormFeedback>
                        ) : null}
                    </Col>
                    <Col md={8}>
                      <Label>Document</Label>
                      <Input
                        disabled={user.status === USERS_STATUS.ACTIVE}
                        name="document"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.document || ""}
                        invalid={
                          validation.touched.document && validation.errors.document ? true : false}
                      />
                      {validation.touched.document &&
                        validation.errors.document ? (
                        <FormFeedback type="invalid">
                          {validation.errors.document}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                </div>
                <div className="mb-3">
                  <Row>
                    <Col md={4}>
                      <Label>Nationality</Label>
                      <Input
                        disabled={user.status === USERS_STATUS.ACTIVE}
                        type="select"
                        name="nationality"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nationality || ''}
                        invalid={
                          validation.touched.nationality && validation.errors.nationality ? true : false}
                      >
                        <option>Select</option>
                        {HAYA_COUNTRIES
                          .map(country => translate.t(country))
                          .sort((a: any, b: any) => ('' + a).localeCompare(b))
                          .map(country => <option value={country}>{country}</option>)}
                      </Input>
                      {validation.touched.nationality && validation.errors.nationality ?
                        (
                          <FormFeedback type="invalid">  {validation.errors.nationality} </FormFeedback>
                        ) : null}
                    </Col>
                    <Col md={8}>
                      <Label>Phone</Label>
                      <Input

                        name="phone"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone || ""}
                        invalid={
                          validation.touched.phone && validation.errors.phone ? true : false}
                      />
                      {validation.touched.phone &&
                        validation.errors.phone ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phone}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                </div>
                <div className="mb-3">
                  <Label>Email</Label>
                  <Input
                    disabled={user.status === USERS_STATUS.ACTIVE}
                    name="email"
                    label="Email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false}
                  />
                  {validation.touched.email && validation.errors.email ?
                    (
                      <FormFeedback type="invalid">   {validation.errors.email} </FormFeedback>
                    ) : null}
                </div>
                <div className="mb-3">
                  <Label>Address</Label>
                  <Input
                    name="address"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address || ""}
                    invalid={
                      validation.touched.address && validation.errors.address ? true : false}
                  />
                  {validation.touched.address && validation.errors.address ?
                    (
                      <FormFeedback type="invalid">   {validation.errors.address} </FormFeedback>
                    ) : null}
                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user"> {!!isEdit ? "Update" : "Add"}  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default (withRouter(PersonalInformation));
