import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

import { toast } from "react-toastify";

import { setCountRequirements, setLocalPaginationData } from "./reducer";

export const getRequirements = createAsyncThunk("RequirementsSlice/getRequirements", async (props: any, { getState, dispatch }) => {
    try {
        // const response = getRequirementsApi();

        const { currentPage, query, pageSize } = props;

        const state: any = getState();
        const requirements_state = state.requirements;

        const { currentPage: currentPageStore, pageSize: pageSizeStore, query: queryStore } = requirements_state;

        let _currentPage = currentPage ? currentPage : currentPageStore;
        let _query = (query || (currentPage && pageSize) ) ? query : queryStore;
        let _pageSize = pageSize ? pageSize : pageSizeStore;
        

        dispatch(setLocalPaginationData({
            currentPage: _currentPage,
            pageSize: _pageSize,
            query: _query
        }));

        const authJWTUser = window.localStorage.authJWTUser;

        const response: any = await axios.post(`/requirements/pagination`, {
            currentPage: _currentPage, query: _query, pageSize: _pageSize
        }, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        dispatch(setCountRequirements({
            currentPage: _currentPage,
            total_pages: Math.ceil(Number(response.count) / _pageSize),
            countRequirements: response.count
        }))

        return response.requirements;
    } catch (error) {
        return error;
    }
});

export const getRequirementsCount = ({ currentPage, pageSize, query }) => async (dispatch: any) => {
    try {
        const authJWTUser = window.localStorage.authJWTUser;

        const response = await axios.post(`/requirements/paginationcount`,{
            query
        }, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        // return response;

        dispatch(setCountRequirements({
            currentPage,
            total_pages: Math.ceil(Number(response) / pageSize),
            countRequirements: response
        }))
    } catch (error) {
        return error;
    }
};


export const getRequirementDetail = createAsyncThunk("requirements/getRequirementDetail", async (requirementId: any) => {
    try {
        // const response = getRequirementDetailApi(requirementId);
        const authJWTUser = window.localStorage.authJWTUser;

        const response = await axios.get(`/requirements/${requirementId}`, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        return response;
    } catch (error) {
        return error;
    }
})

export const createRequirement = createAsyncThunk("requirements/createRequirement", async (props: any, thunkAPI) => {
    try {
        // const response = updateRequirementApi(requirement);

        const authJWTUser = window.localStorage.authJWTUser;

        let _userLogin: any = window.localStorage.getItem("authUser");
        const userLogin = JSON.parse(_userLogin);

        const { requirement, history } = props;

        const data: any = await axios.post("/requirements", {
            requirementtype: requirement.requirement,
            userid: userLogin.id,
            description: requirement.message,
            supports: requirement.documents
        },{
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        thunkAPI.dispatch(getRequirements({}));

        if (data && data.id) {
            history(`/requirement-detail/${data.id}`);

        }

        toast.success("Requirement Create Successfully", { autoClose: 2000 })
        return data;
    } catch (error) {
        toast.error("Requirement Create Failded", { autoClose: 2000 })
        return error
    }
})


export const updateRequirement = createAsyncThunk("requirements/updateRequirement", async (requirement: any, thunkAPI) => {
    try {
        // const response = updateRequirementApi(requirement);

        // let _userLogin: any = window.localStorage.getItem("authUser");
        // const userLogin = JSON.parse(_userLogin);
        const authJWTUser = window.localStorage.authJWTUser;


        const data = await axios.put("/requirements", {
            assignedto: requirement.assignedto,
            documents: requirement.documents,
            requirementid: requirement.requirementid
        }, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });


        thunkAPI.dispatch(getRequirements({}));
        thunkAPI.dispatch(getRequirementDetail(requirement.requirementid));




        toast.success("Requirement Update Successfully", { autoClose: 2000 })
        return data;
    } catch (error) {
        toast.error("Requirement Update Failded", { autoClose: 2000 })
        return error
    }
})


export const updateStatusRequirement = createAsyncThunk("requirements/updateStatusRequirement", async (requirement: any, thunkAPI) => {
    try {

        const authJWTUser = window.localStorage.authJWTUser;

        const data = await axios.put("/requirements/changestatus", {
            status: requirement.status,
            requirementid: requirement.requirementid
        }, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });


        thunkAPI.dispatch(getRequirements({}));
        thunkAPI.dispatch(getRequirementDetail(requirement.requirementid));


        toast.success("Requirement Update Successfully", { autoClose: 2000 })
        return data;
    } catch (error) {
        toast.error("Requirement Update Failded", { autoClose: 2000 })
        return error
    }
})


export const setCommentRequirement = createAsyncThunk("requirements/setCommentRequirement", async (requirement: any, thunkAPI) => {
    try {

        const authJWTUser = window.localStorage.authJWTUser;

        const data = await axios.post("/requirements/comment", {
            comment: requirement.comment,
            requirementid: requirement.requirementid
        }, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        thunkAPI.dispatch(getRequirementDetail(requirement.requirementid));

        toast.success("Requirement Update Successfully", { autoClose: 2000 })
        return data;
    } catch (error) {
        toast.error("Requirement Update Failded", { autoClose: 2000 })
        return error
    }
})



export const deleteRequirement = createAsyncThunk("requirements/deleteRequirement", async (props: any, thunkAPI) => {
    try {
        // const response = deleteRequirementApi(requirement);
        const { requirementid, history } = props;
        const authUser = window.localStorage.authJWTUser;

        const response = await axios.delete(`/requirements/${requirementid}`, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getRequirements({}));
        history('/dashboard');


        toast.success("Requirement Delete Successfully", { autoClose: 2000 })
        return response;
    } catch (error) {
        toast.error("Requirement Delete Failded", { autoClose: 2000 })
        return error;
    }
})