import React, { useCallback, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap"
import { Link } from "react-router-dom";
import { OverviewTeamMember } from "../../../common/data";
import { useTranslation } from "react-i18next";
import { USERS_STATUS, USERS_ROLE } from "../../../helpers/data_helper";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";




import {
  
  changeStatusUser as onChangeStatusUser,
  changePasswordUser as onChangePasswordUser,
  changeRoleUser as onChangeRoleUser,
}
  from "../../../slices/users/thunk"


import ConfirmModal from "Components/Common/ConfirmModal";


const StatusDdnTg = ({ status, translate }) => {
  // console.log(status)
  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <DropdownToggle tag="button" className="btn btn-danger">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case USERS_STATUS.REVIEWING:
      return <DropdownToggle tag="button" className="btn btn-warning">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case USERS_STATUS.ACTIVE:
      return <DropdownToggle tag="button" className="btn btn-success">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    default:
      return <DropdownToggle tag="button" className="btn btn-success">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
  }
}


const RoleDdnTg = ({ role, translate }) => {

  switch (role) {
    case USERS_ROLE.USER:
      return <DropdownToggle tag="button" className="btn btn-success">
        {translate.t(role)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case USERS_ROLE.ADVISOR:
      return <DropdownToggle tag="button" className="btn btn-warning">
        {translate.t(role)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case USERS_ROLE.SECRETARY:
      return <DropdownToggle tag="button" className="btn btn-warning">
        {translate.t(role)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

  }
}

const getStatusText = (status, translate) => {
  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <span className="text-danger"> {translate.t(status)} </span>

    case USERS_STATUS.REVIEWING:
      return <span className="text-warning"> {translate.t(status)} </span>

    case USERS_STATUS.ACTIVE:
      return <span className="text-success"> {translate.t(status)} </span>

    default:
      return <span className="text-success"> {translate.t(status)} </span>
  }
}

const getRoleText = (role, translate) => {
  switch (role) {
    case USERS_ROLE.USER:
      return <span className="text-success"> {translate.t(role)} </span>

    case USERS_ROLE.ADVISOR:
      return <span className="text-danger"> {translate.t(role)} </span>

    case USERS_ROLE.SECRETARY:
      return <span className="text-danger"> {translate.t(role)} </span>

  }
}

const Actions = ({ user, disableActions }) => {

  const translate = useTranslation();
  const [status, setStatus] = useState(false);
  const [role, setRole] = useState(false);

  const dispatch = useDispatch<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Please Enter Your New Password"),
      repeatPassword: Yup.string()
        .required("Please Enter Your Designation")
        .oneOf([Yup.ref('password')], 'Passwords must match')
    }),
    onSubmit: (values: any) => {

      const updateUser = {
        id: user.id,
        password: values.password
      };
      // update user
      dispatch(onChangePasswordUser(updateUser));
      validation.resetForm();
      setIsEdit(false);

      toggle();
    },
  });


  const handleChangePasswordClick = useCallback(() => {

    setIsEdit(true);

    toggle();
  }, [toggle]);



  const [changeStatusModal, setChangeStatusModal] = useState<boolean>(false);
  const [statusSelected, setStatusSelected] = useState<string>("");

  const onToogleChangeStatusModal = () => {
    // setContact(users.id);
    setChangeStatusModal(!changeStatusModal);
  };

  const onOpenChangeStatusModal = (status) => {
    setStatusSelected(status);
    onToogleChangeStatusModal();
  }

  const handleChangeStatusUser = () => {
    dispatch(onChangeStatusUser({
      id: user.id,
      status: statusSelected
    }));
    // onPaginationPageChange(1);
    onToogleChangeStatusModal();
  };


  const [changeRoleModal, setChangeRoleModal] = useState<boolean>(false);
  const [roleSelected, setRoleSelected] = useState<string>("");

  const onToogleChangeRoleModal = () => {
    // setContact(users.id);
    setChangeRoleModal(!changeRoleModal);
  };

  const onOpenChangeRoleModal = (status) => {
    setRoleSelected(status);
    onToogleChangeRoleModal();
  }

  const handleChangeRoleUser = () => {
    dispatch(onChangeRoleUser({
      id: user.id,
      role: roleSelected
    }));

    onToogleChangeRoleModal();
  };


  return (
    <>


      <Card>
        <CardBody>
          {/* <CardTitle className="mb-4">{translate.t("Assigned to")}</CardTitle> */}
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            {!disableActions &&
              <>
                <Dropdown
                  isOpen={role}
                  toggle={() => setRole(!role)}
                >
                  <RoleDdnTg role={user.role} translate={translate} />
                  <DropdownMenu >
                    <DropdownItem onClick={() => onOpenChangeRoleModal(USERS_ROLE.USER)} >{translate.t(USERS_ROLE.USER)} </DropdownItem>
                    <DropdownItem onClick={() => onOpenChangeRoleModal(USERS_ROLE.ADVISOR)}>{translate.t(USERS_ROLE.ADVISOR)} </DropdownItem>
                    <DropdownItem onClick={() => onOpenChangeRoleModal(USERS_ROLE.SECRETARY)}>{translate.t(USERS_ROLE.SECRETARY)} </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  isOpen={status}
                  toggle={() => setStatus(!status)}
                >
                  <StatusDdnTg status={user.status} translate={translate} />
                  <DropdownMenu >
                    <DropdownItem onClick={() => onOpenChangeStatusModal(USERS_STATUS.INACTIVE)} >{translate.t(USERS_STATUS.INACTIVE)} </DropdownItem>
                    <DropdownItem onClick={() => onOpenChangeStatusModal(USERS_STATUS.REVIEWING)}>{translate.t(USERS_STATUS.REVIEWING)} </DropdownItem>
                    <DropdownItem onClick={() => onOpenChangeStatusModal(USERS_STATUS.ACTIVE)}>{translate.t(USERS_STATUS.ACTIVE)} </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            }

            <Button
              color="warning"
              className="btn btn-secondary waves-effect waves-light"
              onClick={(event: any) => {
                event.preventDefault();
                // const userData = cellProps.row.original;
                handleChangePasswordClick();
              }}
            >
              {translate.t("Change password")}
            </Button>
          </div>
        </CardBody>
      </Card>

      {
        !disableActions && <>
          <ConfirmModal show={changeStatusModal} onCloseClick={onToogleChangeStatusModal}>
            <p className="text-muted font-size-16 mb-4">{translate.t("Are you sure you want to set the status to")} {getStatusText(statusSelected, translate)} {translate.t("for user")} {user.name} {user.lastname}?</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-primary" onClick={handleChangeStatusUser}>{translate.t("Set status")}</button>

              <button type="button" className="btn btn-secondary" onClick={onToogleChangeStatusModal}>{translate.t("Close")}</button>
            </div>
          </ConfirmModal>

          <ConfirmModal show={changeRoleModal} onCloseClick={onToogleChangeRoleModal}>
            <p className="text-muted font-size-16 mb-4">{translate.t("Are you sure you want to set the Role to")} {getRoleText(roleSelected, translate)} {translate.t("for user")} {user.name} {user.lastname}?</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-primary" onClick={handleChangeRoleUser}>{translate.t("Set role")}</button>

              <button type="button" className="btn btn-secondary" onClick={onToogleChangeRoleModal}>{translate.t("Close")}</button>
            </div>
          </ConfirmModal>
        </>
      }

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4"> {!!isEdit ? "Edit User" : "Add User"}</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <div className="mb-3">

                  <Label>New Password</Label>
                  <Input
                    name="password"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password ? true : false}
                  />
                  {validation.touched.password &&
                    validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">

                  <Label>Confirm New Password</Label>
                  <Input
                    name="repeatPassword"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.repeatPassword || ""}
                    invalid={
                      validation.touched.repeatPassword && validation.errors.repeatPassword ? true : false}
                  />
                  {validation.touched.repeatPassword &&
                    validation.errors.repeatPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.repeatPassword}
                    </FormFeedback>
                  ) : null}

                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user"> Update </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>

  )
}


export default Actions;
