import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";
import LoginReducer from "./auth/login/reducer";
// import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";
import AccountReducer from "./auth/register/reducer";
// import ProjectsReducer from "./projects/reducer";
import RequirementsReducer from "./requirements/reducer";
import UserReducer from "./users/reducer";
import FilesReducer from "./files/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    // Profile: ProfileReducer,
    ForgetPassword: ForgotPasswordReducer,
    Account: AccountReducer,
    // projects: ProjectsReducer,
    requirements: RequirementsReducer,
    users: UserReducer,
    files: FilesReducer,
});

export default rootReducer;