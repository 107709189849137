import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import axios from "axios";

import { getUserById } from "slices/thunk";


export const downloadFileUser = createAsyncThunk("filesSlice/downloadFile", async (document: any, thunkAPI) => {
    try {

        const authUser = window.localStorage.authJWTUser;

        const _axios = axios.create();

        const response: any = await _axios.get(`/files/${document}`, {
            headers: {
                Authorization: `Bearer ${authUser}`
            },
            responseType: 'blob'
        })

        const href = URL.createObjectURL(response.data);
        const fileName = response.headers["x-suggested-filename"];

        const link = window.document.createElement('a');
        link.href = href;
        link.setAttribute('target', "__blank");
        // link.setAttribute('download', fileName); //or any other extension
        window.document.body.appendChild(link);
        link.click();

        window.document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return "";

    } catch (error) {

        return error
    }
})


export const loadDocumentsUser = createAsyncThunk("filesSlice/loadDocumentsUser", async (user: any, thunkAPI) => {
    try {
        // const response = updateUserApi(customer);


        const authUser = window.localStorage.authJWTUser;

        const response = await axios.put('/user/documents', {
            userid: user.userid,
            documents: user.documents
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        if (user.userid) {
            thunkAPI.dispatch(getUserById(user.userid));
        }

        // const data = await response;
        toast.success("Load Documents Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("Load Documents Failed", { autoClose: 2000 });
        return error
    }
})
