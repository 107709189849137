import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../Components/Common/withRouter";
import { Badge, Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap";

import TableContainer from "Components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";
// import { userProfile as user } from "common/data";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
// import ApexRevenue from "../ApexRevenue";

import AttachedFiles from "./attachedFiles";
import RequireDocuments from "./require-documents";
import PersonalInformation from "./personal-information";

import Actions from "./actions";

import UploadDocuments from "./uploadDocuments";
import { useTranslation } from "react-i18next";
import { USERS_STATUS } from "../../../helpers/data_helper";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
// import {use} from "react-router-dom";

import {
  getUserById as onGetUserById,
  getLoginUserById as ongetLoginUserById
}
  from "../../../slices/users/thunk"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { RequirementStatus } from "../../Requirements/components/status";



const UserStatus = ({ status, translate }: any) => {

  // const history = use

  switch (status) {
    case USERS_STATUS.INACTIVE:
      return <Badge color="danger"> {translate.t(status)} </Badge>;
    case USERS_STATUS.REVIEWING:
      return <Badge color="warning"> {translate.t(status)} </Badge>;
    case USERS_STATUS.ACTIVE:
      return <Badge color="success"> {translate.t(status)} </Badge>;
    default:
      return <Badge color="success"> {translate.t(status)} </Badge>;
  }
};


const UserRequirements = ({ requirements }: any) => {



  const translate = useTranslation();


  const columns = useMemo(
    () => [
      {
        header: translate.t("Requirement"),
        accessorKey: 'description',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {

          let d = document.createElement('div');
          d.innerHTML = cellProps.row.original.description;
          let textContent = d.textContent || d.innerText;

          return (
            <>
              <h5 className="text-truncate font-size-14">
                <Link to={`/requirement-detail/${cellProps.row.original.id}`} className="text-dark">{cellProps.row.original.requirementtype} </Link>
              </h5>
              <p className="text-muted mb-0">
                {String(textContent).substring(0, 30)}...
              </p>
            </>
          )
        }
      },
      {
        header: translate.t("File number"),
        accessorKey: 'documentnumber',
        cell: (cellProps: any) => String(cellProps.row.original.documentnumber).toUpperCase(),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: translate.t("Creation date"),
        accessorKey: 'creationdate',
        cell: (cellProps: any) => moment(cellProps.row.original.creationdate).tz("Europe/Vilnius").fromNow(),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: translate.t('Status'),
        accessorKey: 'status',
        cell: (cellProps: any) => <RequirementStatus status={cellProps.row.original.status} translate={translate} />,
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  );

  return (

    <Card>
      <CardBody>
        <CardTitle className="mb-4">{translate.t("My requirements")}</CardTitle>
        <TableContainer
          columns={columns}
          data={requirements || []}
          isGlobalFilter={false}
          tableClass='table-nowrap table-hover mb-00'
        />
      </CardBody>
    </Card>

  );
};

export default withRouter(UserRequirements);

