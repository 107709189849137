

import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";

import axios from 'axios';


export const setLoginData = (auth:any) => async (dispatch: any) => {
    try {
        localStorage.setItem("authUser", JSON.stringify(auth.user));
        localStorage.setItem("authJWTUser", auth.access_token);
        localStorage.setItem("secureSessionData", auth.secureSessionData);
        dispatch(loginSuccess(auth));
        dispatch({type: "INIT-SESSION", payload:{}});
        // dispatch({type: "INIT-GET-REQUIREMENTS", payload:{}});

    } catch (error) {
        dispatch(apiError(error));
    }
}


export const loginuser = (user: any, history: any) => async (dispatch: any) => {
    try {

        const auth = await axios.post('/auth', {
            user: user.email,
            password: user.password
        })
        dispatch(setLoginData(auth));
        history('/dashboard');

    } catch (error) {
        if(typeof error === 'string' && error.indexOf("409") > -1){
            dispatch(apiError("You have exceeded the number of login attempts! Please try again in 1 hour."));
          }else{
            dispatch(apiError(error));
          }

        
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        dispatch({type: "CLOSE-SESSION", payload:{}});

        localStorage.removeItem("authUser");
        localStorage.removeItem("authJWTUser");
        localStorage.removeItem("secureSessionData");

        dispatch(logoutUserSuccess(true));
        

    } catch (error) {
        dispatch(apiError(error));
    }
};

// export const logoutUser = createAsyncThunk("auth/logoutUser", async (props: any, thunkAPI) => {
//     try {

//         console.log("auth/logoutUser")
//         localStorage.removeItem("authUser");
//         localStorage.removeItem("authJWTUser");
//         localStorage.removeItem("secureSessionData");

//         thunkAPI.dispatch(logoutUserSuccess(true));
//         thunkAPI.dispatch({type: "CLOSE-SESSION", payload:{}});

//     } catch (error) {
//         thunkAPI.dispatch(apiError(error));
//     }
// });


export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};
