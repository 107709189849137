import React, { useEffect } from "react";
import withRouter from "../../../Components/Common/withRouter";
import { isEmpty } from "lodash";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

// import { getProjectsDetail as onGetProjectsDetail } from "../../../slices/projects/thunk";
import {
  getRequirementDetail as onGetRequirementDetail,
  updateRequirement as onUpdateRequirement
} from "../../../slices/requirements/thunk";

import {
  getAdvisors as onGetAdvisors
} from "../../../slices/users/thunk";

import RequirementDetail from "./requirementDetail";
import TeamMembers from "./teamMembers";
import UploadDocuments from "./uploadDocuments";
import OverviewChart from "./overviewChart";
import { options, series } from "common/data/projects";
import AttachedFiles from "./attachedFiles";
import Comments from "./comments";
import Actions from "./actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { useTranslation } from "react-i18next";

import * as Yup from "yup";
import { useFormik } from "formik";
import { REQUEST_STATUS, USERS_ROLE } from "../../../helpers/data_helper";
import { ToastContainer } from "react-toastify";


const ProjectsOverview = (props: any) => {

  const translate = useTranslation();

  //meta title
  document.title = `${translate.t("Requirement Overview")} | ${translate.t("LFPNotary")}`;


  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.requirements,
    (requirements) => ({
      requirementDetail: requirements.requirementDetail,

    })
  );

  const { requirementDetail } = useSelector(selectProperties);

  const selectPropertiesAdvisors = createSelector(
    (state: any) => state.users,
    (users) => ({
      advisors: users.advisorsraw,

    })
  );
  const { advisors } = useSelector(selectPropertiesAdvisors);

  const selectPropertiesLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      userLogin: login.user,

    })
  );
  const { userLogin } = useSelector(selectPropertiesLogin);

  const params = props.router.params;

  // console.log(requirementDetail.requirementsAdvisors);
  // validation
  const validation = useFormik({
    initialValues: {
      assignedto: [],
      documents: [],
    },
    validationSchema: Yup.object({
      assignedto: Yup.array().min(0).required("Please Select Your Requirement")
    }),
    onSubmit: (values: any) => {
      dispatch(onUpdateRequirement({
        assignedto: values.assignedto.map(item => item.userid),
        documents: values.documents,
        requirementid: requirementDetail.id
      }));

    }
  });

  useEffect(() => {
    validation.setFieldValue("assignedto", requirementDetail.requirementsAdvisors);
    // validation.setFieldValue("documents", requirementDetail.supports);
  }, [requirementDetail]);


  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetRequirementDetail(params.id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    dispatch(onGetAdvisors());
    return () => {
      validation.resetForm()
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate.t("Requirement")} breadcrumbItem={translate.t("Requirement Overview")} />

          {!isEmpty(requirementDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <RequirementDetail requirement={requirementDetail} />
                  {requirementDetail.status === REQUEST_STATUS.ACTIVE &&
                    <Comments requirement={requirementDetail} />
                  }
                </Col>

                <Col lg="4">
                  <Form id="createproject-form" onSubmit={(e: any) => {
                    e.preventDefault();
                    // console.log("submit")
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Actions requirement={requirementDetail} userLogin={userLogin} />
                    {(userLogin.role === USERS_ROLE.SECRETARY || userLogin.role === USERS_ROLE.ADMIN) &&
                      <TeamMembers advisors={advisors} validation={validation} />
                    }
                    <UploadDocuments validation={validation} />
                    <AttachedFiles files={requirementDetail.files} />
                  </Form>
                </Col>
              </Row>

            </>
          )}

          {isEmpty(requirementDetail) && (
            <Row>
              <Col lg="8">
                <Card>
                  <CardBody>
                    <h5>
                      {translate.t("Content not available")}.
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          )}

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ProjectsOverview);
