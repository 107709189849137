import React, { useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, UncontrolledTooltip } from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { projectAssignedTo } from "../../../common/data";

import FormDetail from "./formDetail";
// import AttachedFiles from "./attachedFiles";
import Actions from "./actions";
import TeamMembers from "./teamMembers";
import UploadDocuments from "./uploadDocuments";

import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { createRequirement as onCreateRequirement } from "slices/thunk";
import { useDispatch } from "react-redux";
import { USERS_STATUS } from "../../../helpers/data_helper";

const ProjectsCreate = (props) => {

  const translate = useTranslation();
  const dispatch = useDispatch<any>();
  //meta title
  document.title = `${translate.t("Create New Requirement")} | ${translate.t("LFPNotary")}`;

  const selectPropertiesUser = createSelector(
    (state: any) => state.Login,
    (user) => ({
      userLogin: user.user
    })
  );

  const { userLogin } = useSelector(selectPropertiesUser);

  const { router } = props;

  useEffect(() => {
    if (userLogin.status !== USERS_STATUS.ACTIVE) {
      router.navigate("/profile")
    }
  }, [userLogin]);

  // validation
  const validation = useFormik({
    initialValues: {
      requirement: '',
      message: '',
      assignedto: [],
      documents: [],
    },
    validationSchema: Yup.object({
      requirement: Yup.string().required("Please Select Your Requirement"),
      message: Yup.string().required("Please Enter Requirement description"),
    }),
    onSubmit: (values: any) => {
      dispatch(onCreateRequirement({
        requirement: values, 
        history: router.navigate
      }))

    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate.t("Requirements")} breadcrumbItem={translate.t("Create New Requirement")} />


          <Form id="createproject-form" onSubmit={(e: any) => {
            e.preventDefault();
            // console.log("submit")
            validation.handleSubmit();
            return false;
          }}>
            <Row>


              <Col lg={8}>
                <FormDetail validation={validation} />
              </Col>
              <Col lg={4}>
                <Actions />
                {/* <TeamMembers /> */}
                <UploadDocuments validation={validation} />
                {/* <AttachedFiles /> */}
              </Col>
            </Row>

          </Form>

        </Container>
      </div>
    </React.Fragment >
  );
};

// export default ProjectsCreate;

export default withRouter(ProjectsCreate);