import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip, Card, CardBody } from "reactstrap";

//Import Component
import Breadcrumbs from "Components/Common/Breadcrumb";
import DeleteModal from "Components/Common/DeleteModal";


import {
    getRequirements as onGetRequirements,
    deleteRequirement as onDeleteRequirement,
} from "slices/thunk";

import { createSelector } from 'reselect';

//redux
import { useSelector, useDispatch } from "react-redux";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "./components/TableContainer";



import { RequirementStatus } from "./components/status";

import lfpnotary from "assets/images/users/lfpnotary.png";
import { USERS_ROLE } from "../../helpers/data_helper";


const MyRequirements = (props: any) => {


    const translate = useTranslation();

    //meta title
    document.title = `${translate.t("My requirements")} | ${translate.t("LFPNotary")}`;

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [requirement, setRequirement] = useState<any>();
    const [currentPageLocal, setCurrentPageLocal] = useState<number>(1);
    const [queryLocal, setQueryLocal] = useState<string>("");
    const [pageSizeLocal, setPageSizeLocal] = useState<number>(10);


    const selectPropertiesUserLogin = createSelector(
        (state: any) => state.Login,
        (login) => ({
            userLogin: login.user
        })
    );

    const { userLogin } = useSelector(selectPropertiesUserLogin);

    const selectProperties = createSelector(
        (state: any) => state.requirements,
        (requirements) => ({

            requirements: requirements.requirements,
            currentPage: requirements.currentPage,
            paginationData: requirements.paginationData,
            query: requirements.query,
            pageSize: requirements.pageSize,


            loading: requirements.loading
        })
    );

    const { requirements, loading, currentPage, query, pageSize, paginationData } = useSelector(selectProperties);

    const [isLoading, setLoading] = useState<boolean>(loading);


    useEffect(() => {
        setCurrentPageLocal(currentPage);
        setQueryLocal(query);
        setPageSizeLocal(pageSize);
        getRequirements(currentPage, query, pageSize);

        dispatch({type: "INIT-GET-REQUIREMENTS", payload:{}});

        return()=>{
            dispatch({type: "STOP-GET-REQUIREMENTS", payload:{}});
        }
        // dispatch(onGetRequirements({ currentPage, query }));
    }, [dispatch]);


    const getRequirements = (currentPage, query, pageSize) => {
        dispatch(onGetRequirements({ currentPage, query, pageSize }));
    }

    const findRequirement = (value) => {
        setQueryLocal(value);
        setCurrentPageLocal(1);
        getRequirements(1, value, pageSizeLocal);
    }

    const pagination = (value) => {
        setCurrentPageLocal(value);
        getRequirements(value, queryLocal, pageSizeLocal);
    }

    const changePageSize = (value) => {
        setPageSizeLocal(value);
        setCurrentPageLocal(1);
        getRequirements(1, queryLocal, value);
    }



    const handleEditRequirementClick = useCallback((requirementid: any) => {
        navigate(`/requirement-detail/${requirementid}`);
    }, []);

    //delete order
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const onClickDelete = useCallback((_requirement: any) => {
        setRequirement(_requirement);
        setDeleteModal(true);
    }, []);

    const handleDeleteOrder = () => {
        if (requirement && requirement.id) {
            dispatch(onDeleteRequirement({
                requirementid: requirement.id,
                history: navigate
            }));
        }
        setDeleteModal(false);
    };

    const [requirementsData, setRequirementsData] = useState<any>();

    useEffect(() => {
        setRequirementsData(requirements);
    }, [requirements]);


    const handleCreateRequirementClicks = () => {
        navigate("/requirement-create")
    }

    const columns = useMemo(
        () => [
            {
                header: translate.t("Notary"),
                id: "#",
                cell: (cellProps: any) => (
                    <div className="avatar-sm bg-light rounded p-2">
                        <img src={lfpnotary} alt={cellProps.row.original.requirementtype} className="img-fluid rounded-circle" />
                    </div>
                ),
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: translate.t("Requirement"),
                accessorKey: 'description',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {

                    let d = document.createElement('div');
                    d.innerHTML = cellProps.row.original.description;
                    let textContent = d.textContent || d.innerText;

                    return (
                        <>
                            <h5 className="text-truncate font-size-14">
                                <Link to={`/requirement-detail/${cellProps.row.original.id}`} className="text-dark">{cellProps.row.original.requirementtype} </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                {String(textContent).substring(0, 30)}...
                            </p>
                        </>
                    )
                }
            },
            {
                header: translate.t("Owner"),
                hide: userLogin.role === USERS_ROLE.USER,
                accessorKey: 'owner',
                cell: (cellProps: any) => `${cellProps.row.original.owner?.name} ${cellProps.row.original.owner?.lastname}`,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: translate.t("File number"),
                accessorKey: 'documentnumber',
                cell: (cellProps: any) => String(cellProps.row.original.documentnumber).toUpperCase(),
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: translate.t("Creation date"),
                accessorKey: 'creationdate',
                cell: (cellProps: any) => moment(cellProps.row.original.creationdate).tz("Europe/Vilnius").fromNow(),
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: translate.t('Status'),
                accessorKey: 'status',
                cell: (cellProps: any) => <RequirementStatus status={cellProps.row.original.status} translate={translate} />,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: translate.t('Assigned to'),
                accessorKey: 'total',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="avatar-group">
                            {cellProps.row.original.advisor?.map((member: any, index: number) =>
                                <div className="avatar-group-item" key={index}>
                                    <UncontrolledTooltip
                                        placement="right"
                                        target={`UncontrolledTooltipExample-${member.id}`}
                                    >
                                        {translate.t(member.role)}
                                    </UncontrolledTooltip>
                                    <Link to="#" className="d-inline-block" id={`UncontrolledTooltipExample-${member.id}`} >
                                        <div className="avatar-xs">
                                            <span className={`avatar-title rounded-circle bg-${member.color}  text-white font-size-16`}>{(member.name || "").charAt(0)}</span>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )
                }
            },
            {
                header: translate.t('Action'),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" href="#" className="card-drop">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={() => handleEditRequirementClick(cellProps.row.original.id)}>
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />Edit
                                </DropdownItem>

                                {userLogin.role === USERS_ROLE.ADMIN &&
                                    <DropdownItem href="#!" onClick={() => onClickDelete(cellProps.row.original)}>
                                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />Delete
                                    </DropdownItem>
                                }

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                }
            },
        ].filter((item) => !item.hide),
        [
            handleEditRequirementClick, onClickDelete
        ]
    );


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={translate.t("Requirements")} breadcrumbItem={translate.t("My requirements")} />
                    <Row>
                        <Col lg={12}>
                            <div >
                                {
                                    isLoading ? <Spinners setLoading={setLoading} />
                                        :
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <TableContainer
                                                            columns={columns}
                                                            data={requirementsData}
                                                            isGlobalFilter={true}
                                                            isAddButton={true}
                                                            isPagination={true}
                                                            isCustomPageSize={true}
                                                            handleUserClick={handleCreateRequirementClicks}
                                                            SearchPlaceholder={translate.t("Search")}
                                                            buttonClass="btn btn-success btn-rounded"
                                                            buttonName={translate.t("Add New Requirement")}
                                                            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                            theadClass="table-light"
                                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                            pagination="pagination"
                                                            paginationData={paginationData}
                                                            setGlobalFilter={findRequirement}
                                                            setCurrentPage={pagination}
                                                            globalFilter={queryLocal}
                                                            setPageSize={changePageSize}
                                                            pageSize={pageSizeLocal}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                }




                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(MyRequirements);

