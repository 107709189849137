import React, { useState } from "react"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom";
import { OverviewTeamMember } from "../../../common/data";
import { useTranslation } from "react-i18next";

import { setUploadFiles, resetUploadFiles, removeUploadFile } from "slices/requirements/reducer";

import {
  Row,
  Col,

  Form,

  Container,
} from "reactstrap"
import Dropzone from "react-dropzone"
import axios from "axios";
import { useDispatch } from "react-redux";

const TeamMembers = (props: any) => {

  const translate = useTranslation();
  const dispatch = useDispatch();

  const { validation } = props;


  const [selectedFiles, setselectedFiles] = useState<any>([])

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)

    let fd = new FormData();
    files.forEach(_file => {
      fd.append('files', _file)
    });


    axios.post('/files', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response: any) => {
      let _documents = validation.values.documents;
      validation.setFieldValue("documents", [..._documents, ...response]);
    })
  }



  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <Card>
      <CardBody>
        {/* <CardTitle className="mb-4">{translate.t("Assigned to")}</CardTitle> */}


        <h6 className="card-title">{translate.t("Documents")}</h6>
        <div className="card-title-desc">
          {translate.t("Name the documents according to the required list.")}
        </div>
        <Form>
          <Dropzone

            onDrop={(acceptedFiles: any) => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }: any) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick mt-2"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>{translate.t("Drop files here or click to upload.")}</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f: any, i: any) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link
                          to="#"
                          className="text-muted font-weight-bold"
                        >
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>
        </Form>

        {/* <div className="text-center mt-4">
          <button
            type="button"
            className="btn btn-primary "
          >
            Send Files
          </button>
        </div> */}

      </CardBody>
    </Card>
  )
}


export default TeamMembers
