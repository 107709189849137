import { createSlice } from "@reduxjs/toolkit";

import { getUsers, deleteUsers, updateUser, getUserById, getAdvisors } from "./thunk";

export const initialState = {
    users: [],
    advisorsraw: [],
    user: {},
    error: {},
    loading: true,
};

const UsersSlice = createSlice({
    name: 'UsersSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
            state.users = action.payload;
            state.loading = true
        });

        builder.addCase(getUsers.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });

        builder.addCase(getAdvisors.fulfilled, (state: any, action: any) => {
            state.advisorsraw = action.payload;
            state.loading = true
        });

        builder.addCase(getAdvisors.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });

        builder.addCase(getUserById.fulfilled, (state: any, action: any) => {
            state.user = action.payload;
            state.loading = true
        });

        builder.addCase(getUserById.rejected, (state: any, action: any) => {
            state.user = null;
            state.error = action.payload ? action.payload?.error : null;
        });

        builder.addCase(updateUser.fulfilled, (state: any, action: any) => {
            state.users = state.users.map((user: any) =>
                user.id === action.payload.id
                    ? { ...user, ...action.payload }
                    : user
            );
        });

        builder.addCase(updateUser.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });

        builder.addCase(deleteUsers.fulfilled, (state: any, action: any) => {
            state.users = state.users.filter(
                (users: any) => users.id !== action.payload
            );
        });
        builder.addCase(deleteUsers.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
})

export default UsersSlice.reducer;