import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './slices';
import axios from 'axios';
import { setLoginData } from 'slices/thunk';
import rootSaga from 'slices/saga';
import { getLocalStorageAuth } from './helpers/auth_helper';
import moment from 'moment';
import 'moment-timezone';

import 'helpers/api_helper';


import { applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'

import push from 'push.js'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sagaMiddleware)
      // .concat(logger),
});

sagaMiddleware.run(rootSaga)

const initState = () => {
  const { user, jwt, secureSessionData } = getLocalStorageAuth();
  const { Login } = store.getState();

  if (user && jwt && secureSessionData && !Login.user) {
    store.dispatch<any>(setLoginData({
      user: user,
      access_token: jwt,
      secureSessionData
    }))
  }
}

initState();
var m = moment()
m.utcOffset(+3);
// moment.tz.setDefault("Europe/Vilnius");

push.Permission.request(()=>{
  window.localStorage.setItem("allowPush", "true");
}, ()=>{
  window.localStorage.setItem("allowPush", "false");
});

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
