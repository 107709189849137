import { Navigate } from "react-router-dom"
import Dashboard from "../pages/Dashboard";


// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ChangePassword from "pages/Authentication/ChangePassword";
import SignUp from "pages/Authentication/Register"

//Requirements
import MyRequirements from "../pages/Requirements/MyRequirements";
import RequirementCreate from "../pages/Requirements/RequirementCreate/requirement-create";
import RequirementDetail from "../pages/Requirements/RequirementOverview/requirement-overview";

import UsersList from "../pages/Users/users-list";
import AdvisorList from "../pages/Users/advisors-list";
import UserDetailProfile from "../pages/Users/UserProfile/user-profile";
import { USERS_ROLE } from "../helpers/data_helper";

// export const ProtectedRoute = ({ children }) => {
//   // const { user } = useAuth();
//   if (!user) {
//     // user is not authenticated
//     return <Navigate to="/login" />;
//   }
//   return children;
// };


const authProtectedRoutes = [
  { path: "/dashboard", component: <MyRequirements /> },
  { path: "/requirement-create", component: <RequirementCreate /> },
  { path: "/requirement-detail/:id", component: <RequirementDetail /> },
  // { path: "/requirement-list-admin", component: <RequirementsAdminList />, roles: [USERS_ROLE.ADVISOR, USERS_ROLE.SECRETARY, USERS_ROLE.ADMIN] },
  { path: "/users", component: <UsersList />, roles: [USERS_ROLE.ADVISOR, USERS_ROLE.SECRETARY, USERS_ROLE.ADMIN] },
  { path: "/advisors", component: <AdvisorList />, roles: [USERS_ROLE.ADVISOR, USERS_ROLE.SECRETARY, USERS_ROLE.ADMIN] },
  { path: "/user-profile/:id", component: <UserDetailProfile />, roles: [USERS_ROLE.ADVISOR, USERS_ROLE.SECRETARY, USERS_ROLE.ADMIN] },
  { path: "/profile", component: <UserDetailProfile localuser={true} disableActions={true} /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/change-password/:id", component: <ChangePassword /> },
  { path: "/register", component: <SignUp /> }
]
export { authProtectedRoutes, publicRoutes };
