import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap"
import { Link } from "react-router-dom";
import { OverviewTeamMember } from "../../../common/data";
import { useTranslation } from "react-i18next";

import { REQUEST_STATUS, USERS_ROLE } from "../../../helpers/data_helper";
import ConfirmModal from "Components/Common/ConfirmModal";

import { updateStatusRequirement as onUpdateStatusRequirement } from "slices/thunk";
import { useDispatch } from "react-redux";

const getStatusText = (status, translate) => {
  switch (status) {
    case REQUEST_STATUS.CLOSED:
      return <span className="text-danger"> {translate.t(status)} </span>

    case REQUEST_STATUS.REVIEWING:
      return <span className="text-warning"> {translate.t(status)} </span>

    case REQUEST_STATUS.ACTIVE:
      return <span className="text-success"> {translate.t(status)} </span>

    default:
      return <span className="text-success"> {translate.t(status)} </span>
  }
}


const StatusDdnTg = ({ status, translate }) => {
  // console.log(status)
  switch (status) {
    case REQUEST_STATUS.CLOSED:
      return <DropdownToggle tag="button" className="btn btn-danger">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case REQUEST_STATUS.REVIEWING:
      return <DropdownToggle tag="button" className="btn btn-warning">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    case REQUEST_STATUS.ACTIVE:
      return <DropdownToggle tag="button" className="btn btn-success">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

    default:
      return <DropdownToggle tag="button" className="btn btn-success">
        {translate.t(status)} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
  }
}

const Actions = ({ requirement, userLogin }) => {

  const translate = useTranslation();

  const [status, setStatus] = useState(false);

  const dispatch = useDispatch<any>();

  const [changeStatusModal, setChangeStatusModal] = useState<boolean>(false);
  const [statusSelected, setStatusSelected] = useState<string>("");

  const onToogleChangeStatusModal = () => {
    // setContact(users.id);
    setChangeStatusModal(!changeStatusModal);
  };

  const onOpenChangeStatusModal = (status) => {
    setStatusSelected(status);
    onToogleChangeStatusModal();
  }

  const handleChangeStatusUser = () => {
    dispatch(onUpdateStatusRequirement({
      requirementid: requirement.id,
      status: statusSelected
    }));
    // onPaginationPageChange(1);
    onToogleChangeStatusModal();
  };



  return (
    <>
      <Card>
        <CardBody>
          {/* <CardTitle className="mb-4">{translate.t("Assigned to")}</CardTitle> */}
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            {/* <Button
            color="success"
            className="btn btn-primary waves-effect waves-light"
          >
            {translate.t("Close")}
          </Button>
          <Button
            color="warning"
            className="btn btn-secondary waves-effect waves-light"
          >
            {translate.t("Stop")}
          </Button> */}
            {(userLogin.role === USERS_ROLE.SECRETARY || userLogin.role === USERS_ROLE.ADMIN) &&

              <Dropdown
                isOpen={status}
                toggle={() => setStatus(!status)}
              >
                <StatusDdnTg status={requirement.status} translate={translate} />
                <DropdownMenu >
                  <DropdownItem onClick={() => onOpenChangeStatusModal(REQUEST_STATUS.ACTIVE)}>{translate.t(REQUEST_STATUS.ACTIVE)} </DropdownItem>
                  <DropdownItem onClick={() => onOpenChangeStatusModal(REQUEST_STATUS.REVIEWING)}>{translate.t(REQUEST_STATUS.REVIEWING)} </DropdownItem>
                  <DropdownItem onClick={() => onOpenChangeStatusModal(REQUEST_STATUS.CLOSED)} >{translate.t(REQUEST_STATUS.CLOSED)} </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            }

            <Button
              color="primary"
              type="submit"
              className="btn btn-primary waves-effect waves-light"
            >
              {translate.t("Public")}
            </Button>
          </div>
        </CardBody>
      </Card>
      <ConfirmModal show={changeStatusModal} onCloseClick={onToogleChangeStatusModal}>
        <p className="text-muted font-size-16 mb-4">{translate.t("Are you sure you want to set the status to")} {getStatusText(statusSelected, translate)}?</p>

        <div className="hstack gap-2 justify-content-center mb-0">
          <button type="button" className="btn btn-primary" onClick={handleChangeStatusUser}>{translate.t("Set status")}</button>

          <button type="button" className="btn btn-secondary" onClick={onToogleChangeStatusModal}>{translate.t("Close")}</button>
        </div>
      </ConfirmModal>
    </>
  )
}


export default Actions;
