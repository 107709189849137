import companies01 from "assets/images/companies/img-1.png";
import companies02 from "assets/images/companies/img-2.png";
import companies03 from "assets/images/companies/img-3.png";
import companies04 from "assets/images/companies/img-4.png";
import companies05 from "assets/images/companies/img-5.png";
import companies06 from "assets/images/companies/img-6.png";
import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import avatar3 from "assets/images/users/avatar-3.jpg";
import avatar4 from "assets/images/users/avatar-4.jpg";
import avatar5 from "assets/images/users/avatar-5.jpg";
import avatar6 from "assets/images/users/avatar-6.jpg";
import avatar7 from "assets/images/users/avatar-7.jpg";
import avatar8 from "assets/images/users/avatar-8.jpg";
import lfpnotary from "assets/images/users/lfpnotary.png";

const requirements = [
    {
        "id": "1",
        "file_number": "2C72355K",
        "notary_name": "LFPNotary",
        "notary_full_name": "London Financial Public Notary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "reviewing",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "Lorem0 Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "2",
        "file_number": "4FCBH53Y",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "outstanding payment",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igua",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "3",
        "file_number": "G58T5W9M",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "payment validation",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "l al original. Fue popularizado en los 60s con la creación de las hojas \"Letraset\", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "4",
        "file_number": "Q6XUQ322",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "open",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "más o menos normal de las letras, al contrario de usar textos como por ejemplo \"Contenido aquí, contenido aquí\". Estos textos hacen parecerlo un español que se puede leer. Muchos paquetes de autoe",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "5",
        "file_number": "Y2KU45UI",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "close",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "xto por defecto, y al hacer una búsqueda de \"Lorem Ipsum\" va a dar por resultado muchos sitios web que usan este texto si se encuentran e",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "6",
        "file_number": "DR8RDI82",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "stop",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "con la creación de las hojas \"Letraset\", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    },
    {
        "id": "7",
        "file_number": "JWF55E42",
        "notary_name": "LFPNotary",
        "notary_img": lfpnotary,
        color: "warning",
        "requirement_type_name": "Apostille",
        "requirement_type_id": "1",
        "requirement_documents": [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ],
        "creation_date": "5/05/2024 5:12",
        "status": "reviewing",
        assign: [
            {
                id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"]
            }
        ],
        "description": "textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando",
        files: [
            { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
            { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
            { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
            { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
            {
                id: 1,
                username: "David Lambert",
                userImg: avatar2,
                comment: "Separate existence is a myth.",
            },
            {
                id: 2,
                username: "Steve Foster",
                userImg: avatar3,
                comment: "@Henry To an English person it will like simplified",
                reply: {
                    username: "Jeffrey Walker",
                    comment: "as a skeptical Cambridge friend",
                },
            },
            {
                id: 3,
                username: "Steven Carlson",
                comment: " Separate existence is a myth.",
            },
        ],

    }
];

const advisors = [
    { id: 1, img: lfpnotary, fullname: "Secretary", skills: ["Secretary"], issecretary: true },
    { id: 2, img: lfpnotary, fullname: "James Anderson", skills: ["Notary"] },
    { id: 3, img: lfpnotary, fullname: "Elizabeth Morgan", skills: ["Notary"] },
    { id: 4, img: lfpnotary, fullname: "William Thompson", skills: ["Notary"] }
]

const requirement_data = [
    {
        requirement: "Apostille", requiredocuments: [
            "Original Document to Be Apostilled",
            "Identification Documents",
            "Completed Application Form",
            "Payment of Fees",
            "Translation (if Necessary)",
            "Additional Supporting Documents"
        ]
    },
    { requirement: "Administration of oaths and declarations", requiredocuments: [] },
    { requirement: "Attesting signatures and execution of documents", requiredocuments: [] },
    { requirement: "Certification of academic documents", requiredocuments: [] },
    { requirement: "Change of name documents", requiredocuments: [] },
    { requirement: "Consular Legalisation Services", requiredocuments: [] },
    { requirement: "Documents for working abroad", requiredocuments: [] },
    { requirement: "International affidavits", requiredocuments: [] },
    { requirement: "Mobile Notary Services", requiredocuments: [] },
    { requirement: "Notarisation", requiredocuments: [] },
    { requirement: "Notarised copies of identification and other documents", requiredocuments: [] },
    { requirement: "Powers of attorney", requiredocuments: [] },
    { requirement: "Travel consents", requiredocuments: [] },
]


export { requirements, advisors, requirement_data };