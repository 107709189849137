import React from "react";
import { Button, Card, CardBody, CardTitle, Col, FormFeedback, Input, Label, Row, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";

import { setCommentRequirement as onSetCommentRequirement } from "slices/thunk";
import { useDispatch } from "react-redux";

const Comments = ({ requirement }) => {
  const translate = useTranslation();
  const dispatch = useDispatch<any>();


  const validation:any = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: ""
    },
    validationSchema: Yup.object({
      comment: Yup
      .string()
      .required(translate.t("Please Enter Your Question"))
      .max(2000,`${translate.t("The maximum length is")} 2000` ),
    }),
    onSubmit: (values: any) => {
      // dispatch(onCreateRequirement(values))
      // console.log(values)

      dispatch(onSetCommentRequirement({
        requirementid: requirement.id,
        comment: values.comment
      }));
      validation.resetForm();


    }
  });

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{translate.t("Comments")}</CardTitle>

        <div className="text-center mb-4 mt-2 pt-2">
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >

            <Row>
              <Col>

                <Input
                  name="comment"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.comment || ""}
                  maxLength={2000}
                  invalid={
                    validation.touched.comment && validation.errors.comment ? true : false}
                />
                {validation.touched.comment &&
                  validation.errors.comment ? (
                  <FormFeedback type="invalid"> {validation.errors.comment} </FormFeedback>
                ) : null}
              </Col>

            </Row>
            <Button type="submit" className="btn btn-primary btn-sm mt-2">
              {translate.t("Comment")}
            </Button>

          </Form>
        </div>

        {(requirement.comments || [])?.map((comment: any, index: number) => (
          <div className="d-flex mb-4" key={index}>
            <div className="me-3">
              {comment.userImg ? (
                <img
                  className="media-object rounded-circle avatar-xs"
                  alt=""
                  src={comment.userImg}
                />
              ) : (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-16">
                    {comment.user.name.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <div className="flex-grow-1">
              <h5 className="font-size-13 mb-1"> {comment.user.name} {comment.user.lastname}</h5>
              <p className="text-muted mb-1">{comment.message}</p>
              {/* {comment.reply && (
                <div className="d-flex mt-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-xs">
                      {comment.reply.userImg ? (
                        <img
                          className="media-object rounded-circle avatar-xs"
                          alt=""
                          src={comment.userImg}
                        />
                      ) : (
                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-16">
                          {comment.reply.username.charAt(0)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="font-size-13 mb-1">Jeffrey Walker</h5>
                    <p className="text-muted mb-1">
                      as a skeptical Cambridge friend
                    </p>
                  </div>
                  <div className="ms-3">
                    <Link to="#" className="text-primary">
                      Reply
                    </Link>
                  </div>
                </div>
              )} */}
            </div>
            {/* <div className="ml-3">
              <Link to="#" className="text-primary">
                Reply
              </Link>
            </div> */}
          </div>
        ))}

      </CardBody>
    </Card>
  );
};

export default Comments;
