import { userForgetPasswordSuccess, userForgetPasswordError, setHashRecover } from "./reducer"


import axios from "axios";
import { toast } from "react-toastify";


export const userForgetPassword = (props: any) => async (dispatch) => {
    try {
        const { email, history } = props;

        const response = await axios.post('/auth/forget-password', {
            email: email
        });

        const data = await response;

        history('/login');
        toast.success("Reset link are sended to your mailbox, check there first", { autoClose: 2000 })
        if (data) {
            dispatch(userForgetPasswordSuccess(
                "Reset link are sended to your mailbox, check there first"
            ))
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError))
    }
}


export const validateHashForgotPassword = (props: any) => async (dispatch) => {
    const { history } = props;
    try {
        const { hash } = props;

        const response = await axios.get('/auth/forget-password', {
            headers: {
                Authorization: `Bearer ${hash}`
            }
        });

        if (response) {
            dispatch(setHashRecover(response));
        } else {
            history('/login');
            toast.error("Invalid link", { autoClose: 2000 })
        }
    } catch (forgetError) {
        // dispatch(userForgetPasswordError(forgetError))
        history('/login');
        toast.error("Invalid link", { autoClose: 2000 })
    }
}


export const userChangePassword = (props: any) => async (dispatch) => {
    try {
        const { pass, hash, history } = props;

        const response = await axios.post('/auth/change-password', {
            pass: pass
        },{
            headers: {
                Authorization: `Bearer ${hash}`
            }
        });


        history('/login');
        toast.success("Change password successfully", { autoClose: 2000 })
        
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError))
    }
}