import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"

const users = [
  {
    "id": 1,
    "status": "active",
    "name": "Sergio",
    "lastname": "García",
    "documenttype": "Pasaporte",
    "document": "39143324",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "30030",
    "mail": "sergio.garcía26@ejemplo.com",
    "phone": "5529955557",
    "nationality": "Mexicana",
    "birthdate": "2001-10-29",
    "creation_date": "2024-02-22",
    "documentdate": "2011-08-16"
  },
  {
    "id": 2,
    "status": "inactive",
    "name": "Alberto",
    "lastname": "Pérez",
    "documenttype": "Pasaporte",
    "document": "80334886",
    "address": "Calle Falsa 123",
    "postalcode": "20020",
    "mail": "alberto.pérez69@ejemplo.com",
    "phone": "3322423638",
    "nationality": "Mexicana",
    "birthdate": "1987-05-13",
    "creation_date": "2024-02-22",
    "documentdate": "2015-01-10"
  },
  {
    "id": 3,
    "status": "reviewing",
    "name": "Andrés",
    "lastname": "González",
    "documenttype": "Pasaporte",
    "document": "16037424",
    "address": "Calle Falsa 123",
    "postalcode": "01000",
    "mail": "andrés.gonzález47@ejemplo.com",
    "phone": "72265612300",
    "nationality": "Mexicana",
    "birthdate": "1959-09-18",
    "creation_date": "2024-02-22",
    "documentdate": "2012-06-23"
  },
  {
    "id": 4,
    "status": "active",
    "name": "Luis",
    "lastname": "Pérez",
    "documenttype": "Pasaporte",
    "document": "75695135",
    "address": "Callejón Diagon 101",
    "postalcode": "02900",
    "mail": "luis.pérez65@ejemplo.com",
    "phone": "65682344654",
    "nationality": "Mexicana",
    "birthdate": "1950-02-10",
    "creation_date": "2024-02-22",
    "documentdate": "2020-10-03"
  },
  {
    "id": 5,
    "status": "inactive",
    "name": "Andrés",
    "lastname": "González",
    "documenttype": "Pasaporte",
    "document": "66103914",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "02900",
    "mail": "andrés.gonzález17@ejemplo.com",
    "phone": "8127581310",
    "nationality": "Mexicana",
    "birthdate": "1950-02-27",
    "creation_date": "2024-02-22",
    "documentdate": "2020-07-12"
  },
  {
    "id": 6,
    "status": "reviewing",
    "name": "Jorge",
    "lastname": "López",
    "documenttype": "INE",
    "document": "21948756",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "10010",
    "mail": "jorge.lópez38@ejemplo.com",
    "phone": "8125674890",
    "nationality": "Mexicana",
    "birthdate": "1965-03-15",
    "creation_date": "2024-02-22",
    "documentdate": "2010-11-20"
  },
  {
    "id": 7,
    "status": "active",
    "name": "Carlos",
    "lastname": "Sánchez",
    "documenttype": "Cédula Profesional",
    "document": "58741236",
    "address": "Ruta 66 202",
    "postalcode": "01000",
    "mail": "carlos.sánchez24@ejemplo.com",
    "phone": "5547126589",
    "nationality": "Mexicana",
    "birthdate": "1978-07-22",
    "creation_date": "2024-02-22",
    "documentdate": "2018-05-30"
  },
  {
    "id": 8,
    "status": "inactive",
    "name": "Miguel",
    "lastname": "Rodríguez",
    "documenttype": "Pasaporte",
    "document": "64738291",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "30030",
    "mail": "miguel.rodríguez91@ejemplo.com",
    "phone": "3356781234",
    "nationality": "Mexicana",
    "birthdate": "1984-04-09",
    "creation_date": "2024-02-22",
    "documentdate": "2013-02-17"
  },
  {
    "id": 9,
    "status": "reviewing",
    "name": "Fernando",
    "lastname": "Martínez",
    "documenttype": "INE",
    "document": "54776258",
    "address": "Callejón Diagon 101",
    "postalcode": "10010",
    "mail": "fernando.martínez68@ejemplo.com",
    "phone": "8111176257",
    "nationality": "Mexicana",
    "birthdate": "2001-10-16",
    "creation_date": "2024-02-22",
    "documentdate": "2016-01-28"
  },
  {
    "id": 10,
    "status": "active",
    "name": "Miguel",
    "lastname": "Rodríguez",
    "documenttype": "Pasaporte",
    "document": "74772592",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "20020",
    "mail": "miguel.rodríguez30@ejemplo.com",
    "phone": "3374382282",
    "nationality": "Mexicana",
    "birthdate": "1952-09-29",
    "creation_date": "2024-02-22",
    "documentdate": "2020-05-20"
  },
  {
    "id": 11,
    "status": "inactive",
    "name": "Jorge",
    "lastname": "Rodríguez",
    "documenttype": "Pasaporte",
    "document": "55084728",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "01000",
    "mail": "jorge.rodríguez43@ejemplo.com",
    "phone": "8143501261",
    "nationality": "Mexicana",
    "birthdate": "1979-09-26",
    "creation_date": "2024-02-22",
    "documentdate": "2019-02-14"
  },
  {
    "id": 12,
    "status": "reviewing",
    "name": "Fernando",
    "lastname": "Sánchez",
    "documenttype": "Pasaporte",
    "document": "25859274",
    "address": "Ruta 66 202",
    "postalcode": "20020",
    "mail": "fernando.sánchez58@ejemplo.com",
    "phone": "3355765271",
    "nationality": "Mexicana",
    "birthdate": "1965-01-16",
    "creation_date": "2024-02-22",
    "documentdate": "2020-02-09"
  },
  {
    "id": 13,
    "status": "active",
    "name": "Luis",
    "lastname": "López",
    "documenttype": "Pasaporte",
    "document": "41303657",
    "address": "Callejón Diagon 101",
    "postalcode": "20020",
    "mail": "luis.lópez52@ejemplo.com",
    "phone": "8152192093",
    "nationality": "Mexicana",
    "birthdate": "1999-07-05",
    "creation_date": "2024-02-22",
    "documentdate": "2021-03-06"
  },
  {
    "id": 14,
    "status": "inactive",
    "name": "Alejandro",
    "lastname": "Cruz",
    "documenttype": "Cédula Profesional",
    "document": "89237465",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "30030",
    "mail": "alejandro.cruz77@ejemplo.com",
    "phone": "3356892145",
    "nationality": "Mexicana",
    "birthdate": "1982-11-12",
    "creation_date": "2024-02-22",
    "documentdate": "2014-09-23"
  },
  {
    "id": 15,
    "status": "reviewing",
    "name": "Juan",
    "lastname": "Hernández",
    "documenttype": "INE",
    "document": "23458796",
    "address": "Calle Falsa 123",
    "postalcode": "10010",
    "mail": "juan.hernández15@ejemplo.com",
    "phone": "5587963214",
    "nationality": "Mexicana",
    "birthdate": "1974-06-30",
    "creation_date": "2024-02-22",
    "documentdate": "2012-04-18"
  },
  {
    "id": 16,
    "status": "active",
    "name": "Carlos",
    "lastname": "González",
    "documenttype": "Pasaporte",
    "document": "87654321",
    "address": "Callejón Diagon 101",
    "postalcode": "02900",
    "mail": "carlos.gonzález89@ejemplo.com",
    "phone": "5524687970",
    "nationality": "Mexicana",
    "birthdate": "1989-12-05",
    "creation_date": "2024-02-22",
    "documentdate": "2017-08-11"
  },
  {
    "id": 17,
    "status": "inactive",
    "name": "Sergio",
    "lastname": "Martínez",
    "documenttype": "INE",
    "document": "98456123",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "01000",
    "mail": "sergio.martínez34@ejemplo.com",
    "phone": "5567890123",
    "nationality": "Mexicana",
    "birthdate": "1990-03-21",
    "creation_date": "2024-02-22",
    "documentdate": "2018-06-15"
  },
  {
    "id": 18,
    "status": "reviewing",
    "name": "Alejandro",
    "lastname": "López",
    "documenttype": "Cédula Profesional",
    "document": "58214693",
    "address": "Ruta 66 202",
    "postalcode": "30030",
    "mail": "alejandro.lopez56@ejemplo.com",
    "phone": "3312456789",
    "nationality": "Mexicana",
    "birthdate": "1985-07-29",
    "creation_date": "2024-02-22",
    "documentdate": "2019-11-22"
  },
  {
    "id": 19,
    "status": "active",
    "name": "Fernando",
    "lastname": "García",
    "documenttype": "Pasaporte",
    "document": "78651234",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "20020",
    "mail": "fernando.garcia22@ejemplo.com",
    "phone": "5512345678",
    "nationality": "Mexicana",
    "birthdate": "1976-05-14",
    "creation_date": "2024-02-22",
    "documentdate": "2020-01-30"
  },
  {
    "id": 20,
    "status": "inactive",
    "name": "Carlos",
    "lastname": "Ramírez",
    "documenttype": "INE",
    "document": "34567891",
    "address": "Callejón Diagon 101",
    "postalcode": "02900",
    "mail": "carlos.ramirez89@ejemplo.com",
    "phone": "5543216789",
    "nationality": "Mexicana",
    "birthdate": "1971-08-16",
    "creation_date": "2024-02-22",
    "documentdate": "2015-07-19"
  },
  {
    "id": 21,
    "status": "reviewing",
    "name": "Jorge",
    "lastname": "Sánchez",
    "documenttype": "Cédula Profesional",
    "document": "78912345",
    "address": "Calle Falsa 123",
    "postalcode": "10010",
    "mail": "jorge.sanchez73@ejemplo.com",
    "phone": "5587654321",
    "nationality": "Mexicana",
    "birthdate": "1968-12-03",
    "creation_date": "2024-02-22",
    "documentdate": "2016-09-05"
  },
  {
    "id": 22,
    "status": "active",
    "name": "Miguel",
    "lastname": "Cruz",
    "documenttype": "Pasaporte",
    "document": "87654329",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "01000",
    "mail": "miguel.cruz58@ejemplo.com",
    "phone": "5512349876",
    "nationality": "Mexicana",
    "birthdate": "1973-04-06",
    "creation_date": "2024-02-22",
    "documentdate": "2017-02-14"
  },
  {
    "id": 23,
    "status": "inactive",
    "name": "Luis",
    "lastname": "Martínez",
    "documenttype": "INE",
    "document": "19283746",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "30030",
    "mail": "luis.martinez40@ejemplo.com",
    "phone": "5529876543",
    "nationality": "Mexicana",
    "birthdate": "1988-11-19",
    "creation_date": "2024-02-22",
    "documentdate": "2013-05-20"
  },
  {
    "id": 24,
    "status": "reviewing",
    "name": "Alberto",
    "lastname": "González",
    "documenttype": "Cédula Profesional",
    "document": "45678912",
    "address": "Ruta 66 202",
    "postalcode": "20020",
    "mail": "alberto.gonzalez24@ejemplo.com",
    "phone": "5567894321",
    "nationality": "Mexicana",
    "birthdate": "1969-09-15",
    "creation_date": "2024-02-22",
    "documentdate": "2014-03-25"
  },
  {
    "id": 25,
    "status": "active",
    "name": "Andrés",
    "lastname": "Pérez",
    "documenttype": "Pasaporte",
    "document": "56789123",
    "address": "Callejón Diagon 101",
    "postalcode": "02900",
    "mail": "andres.perez76@ejemplo.com",
    "phone": "5581234567",
    "nationality": "Mexicana",
    "birthdate": "1982-01-22",
    "creation_date": "2024-02-22",
    "documentdate": "2018-10-13"
  },
  {
    "id": 26,
    "status": "inactive",
    "name": "Sergio",
    "lastname": "Rodríguez",
    "documenttype": "INE",
    "document": "67891234",
    "address": "Avenida Siempre Viva 456",
    "postalcode": "10010",
    "mail": "sergio.rodriguez34@ejemplo.com",
    "phone": "5598765432",
    "nationality": "Mexicana",
    "birthdate": "1992-03-08",
    "creation_date": "2024-02-22",
    "documentdate": "2019-06-21"
  },
  {
    "id": 27,
    "status": "reviewing",
    "name": "Juan",
    "lastname": "López",
    "documenttype": "Cédula Profesional",
    "document": "78912346",
    "address": "Boulevard de los Sueños Rotos 789",
    "postalcode": "30030",
    "mail": "juan.lopez59@ejemplo.com",
    "phone": "5512345678",
    "nationality": "Mexicana",
    "birthdate": "1964-07-27",
    "creation_date": "2024-02-22",
    "documentdate": "2012-12-31"
  },
  {
    "id": 28,
    "status": "active",
    "name": "Alejandro",
    "lastname": "Martínez",
    "documenttype": "Pasaporte",
    "document": "89123457",
    "address": "Calle Falsa 123",
    "postalcode": "20020",
    "mail": "alejandro.martinez45@ejemplo.com",
    "phone": "5598763214",
    "nationality": "Mexicana",
    "birthdate": "1970-11-05",
    "creation_date": "2024-02-22",
    "documentdate": "2015-07-20"
  },
  {
    "id": 29,
    "status": "inactive",
    "name": "Carlos",
    "lastname": "Hernández",
    "documenttype": "INE",
    "document": "91234568",
    "address": "Ruta 66 202",
    "postalcode": "01000",
    "mail": "carlos.hernandez67@ejemplo.com",
    "phone": "5581236789",
    "nationality": "Mexicana",
    "birthdate": "1986-02-14",
    "creation_date": "2024-02-22",
    "documentdate": "2017-04-18"
  }
];


const _users = [
  {
    id: 1,
    name: "David McHenry",
    designation: "UI/UX Designer",
    color: "primary",
    email: "david@skote.com",
    projects: "125",
    tags: ["Photoshop", "illustrator"],
  },
  {
    id: 2,
    img: avatar2,
    name: "Frank Kirk",
    designation: "Frontend Developer",
    email: "frank@skote.com",
    projects: "132",
    tags: ["Html", "Css", "Php"],
  },
  {
    id: 3,
    img: avatar3,
    name: "Rafael Morales",
    designation: "Backend Developer",
    email: "Rafael@skote.com",
    projects: "1112",
    tags: ["Php", "Java", "Python", "Html"],
  },
  {
    id: 4,
    name: "Mark Ellison",
    designation: "Full Stack Developer",
    color: "success",
    email: "mark@skote.com",
    projects: "121",
    tags: ["Ruby", "Php", "UI/UX Designer"],
  },
  {
    id: 5,
    img: avatar4,
    name: "Minnie Walter",
    designation: "Frontend Developer",
    email: "minnie@skote.com",
    projects: "145",
    tags: ["Html", "Css", "Java"],
  },
  {
    id: 6,
    img: avatar5,
    name: "Shirley Smith",
    designation: "UI/UX Designer",
    email: "shirley@skote.com",
    projects: "136",
    tags: ["Photoshop", "illustrator"],
  },
  {
    id: 7,
    name: "John Santiago",
    designation: "Full Stack Developer",
    color: "info",
    email: "john@skote.com",
    projects: "125",
    tags: ["Ruby", "Php", "Java"],
  },
  {
    id: 8,
    img: avatar7,
    name: "Colin Melton",
    designation: "Backend Developer",
    color: "",
    email: "colin@skote.com",
    projects: "136",
    tags: ["Php", "Java", "Python"],
  },
]
const userProfile = {
  "id": 5,
  "status": "inactive",
  "name": "Andrés",
  "lastname": "González",
  "documenttype": "Pasaporte",
  "document": "66103914",
  "address": "Avenida Siempre Viva 456",
  "postalcode": "02900",
  "mail": "andrés.gonzález17@ejemplo.com",
  "phone": "8127581310",
  "nationality": "Mexicana",
  "birthdate": "1950-02-27",
  "creation_date": "2024-02-22",
  "documentdate": "2020-07-12",
  designation: "UI/UX Designer",
  img: avatar1,
  projectCount: 125,
  revenue: 1245,
  personalDetail:
    "Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.",
  
  email: "cynthiaskote@gmail.com",
  location: "California, United States",
  tags:[],
  experiences: [
    {
      id: 1,
      iconClass: "bx-server",
      link: "#",
      designation: "Back end Developer",
      timeDuration: "2016 - 19",
    },
    {
      id: 2,
      iconClass: "bx-code",
      link: "#",
      designation: "Front end Developer",
      timeDuration: "2013 - 16",
    },
    {
      id: 3,
      iconClass: "bx-edit",
      link: "#",
      designation: "UI /UX Designer",
      timeDuration: "2011 - 13",
    },
  ],
  projects: [
    {
      id: 1,
      name: "Skote admin UI",
      startDate: "2 Sep, 2019",
      deadline: "20 Oct, 2019",
      budget: "$506",
    },
    {
      id: 2,
      name: "Skote admin Logo",
      startDate: "1 Sep, 2019",
      deadline: "2 Sep, 2019",
      budget: "$94",
    },
    {
      id: 3,
      name: "Redesign - Landing page",
      startDate: "21 Sep, 2019",
      deadline: "29 Sep, 2019",
      budget: "$156",
    },
    {
      id: 4,
      name: "App Landing UI",
      startDate: "29 Sep, 2019",
      deadline: "04 Oct, 2019",
      budget: "$122",
    },
    {
      id: 5,
      name: "Blog Template",
      startDate: "05 Oct, 2019",
      deadline: "16 Oct, 2019",
      budget: "$164",
    },
    {
      id: 6,
      name: "Redesign - Multipurpose Landing",
      startDate: "17 Oct, 2019",
      deadline: "05 Nov, 2019",
      budget: "$192",
    },
    {
      id: 7,
      name: "Logo Branding",
      startDate: "04 Nov, 2019",
      deadline: "05 Nov, 2019",
      budget: "$94",
    },
  ],
}
export { users, userProfile }