export const getLocalStorageAuth = () => {
    const authUser: any = window.localStorage.getItem("authUser");
    const authJWTUser = window.localStorage.getItem("authJWTUser");
    const secureSessionData = window.localStorage.getItem("secureSessionData");
    return {
        user: validateIsJson(authUser),
        jwt: authJWTUser,
        secureSessionData
    };

}


const validateIsJson = (authUser: any) => {
    return [authUser]
        .filter(item => !!item && typeof(item) === 'string')
        .map((item: any) => {
            try {
                return JSON.parse(item)
            } catch (error) {
                return null
            }
        })
        .filter(item => !!item)
        .pop();
}