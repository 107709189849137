const USERS_STATUS = {
    ACTIVE: "active",
    INACTIVE: "inactive",
    REVIEWING: "reviewing"
}

const USERS_ROLE = {
    USER: 'user',
    ADVISOR: 'advisor',
    ADMIN: 'admin',
    SECRETARY: 'secretary'
}

const DOCUMENT_TYPES = {
    PASSPORTS: "Passports",
    NATIONAL_IDENTITY_CARDS: "National Identity Cards",
    BIRTH_CERTIFICATES: "Birth Certificates",
    DRIVING_LICENCES: "Driving Licences",
    RESIDENT_IDENTIFICATION_CARDS: "Resident Identification Cards",
}


const REQUEST_STATUS = {
    ACTIVE: "active",
    REVIEWING: "reviewing",
    CLOSED: "close"
}


const REQUIREMENT_DOCUMENTS = [
    "Original Document to Be Apostilled",
    "Identification Documents",
    "Completed Application Form",
    "Payment of Fees",
    "Translation (if Necessary)",
    "Additional Supporting Documents"
];

const HAYA_COUNTRIES = ["Albania", "Germany", "Andorra", "Antigua and Barbuda", "Saudi Arabia", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Barbados", "Belgium", "Belize", "India", "Belarus", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei Darussalam", "Bulgaria", "Burundi", "Cape Verde", "Canada", "Chile", "China", "Cyprus", "Colombia", "Cook Islands", "South Korea", "Costa Rica", "Croatia", "Denmark", "Dominica", "Ecuador", "El Salvador", "Slovakia", "Slovenia", "Spain", "United States", "Estonia", "Eswatini", "Fiji", "Philippines", "Finland", "France", "Georgia", "Grenada", "Greece", "Guatemala", "Guyana", "Honduras", "Hong Kong", "Hungary", "Indonesia", "Ireland", "Iceland", "Marshall Islands", "Israel", "Italy", "Jamaica", "Japan", "Kazakhstan", "Kyrgyzstan", "Kosovo", "Lesotho", "Latvia", "Liberia", "Liechtenstein", "Lithuania", "Luxembourg", "Macao", "North Macedonia", "Malawi", "Malta", "Morocco", "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Namibia", "Nicaragua", "Niue", "Norway", "New Zealand", "Oman", "Netherlands", "Palau", "Panama", "Paraguay", "Peru", "Pakistan", "Poland", "Portugal", "United Kingdom", "Czech Republic", "Dominican Republic", "Romania", "Russia", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Senegal", "Serbia", "Seychelles", "Singapore", "South Africa", "Sweden", "Switzerland", "Suriname", "Tajikistan", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Ukraine", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela"];

export { USERS_STATUS, DOCUMENT_TYPES, HAYA_COUNTRIES, USERS_ROLE, REQUIREMENT_DOCUMENTS, REQUEST_STATUS };
