//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";



//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/projects";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//REQUIREMENTS
export const GET_REQUIREMENTS = "/requirement";
export const GET_REQUIREMENT_DETAIL = "/requirement";
export const UPDATE_REQUIREMENT = "/update/requirement";
export const DELETE_REQUIREMENT = "/delete/requirement";

//CONTACTS
export const GET_USERS = "/apps/users";
export const GET_USER_BY_ID = "/apps/user";
export const ADD_NEW_USERS = "/apps/users";
export const UPDATE_USERS = "/apps/users";
export const DELETE_USERS = "/delete/users/";
export const GET_USER_PROFILE = "/user";
