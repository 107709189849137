
// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange
} from "./reducer";
import axios from "axios";
import { toast } from "react-toastify";
import { getUsers as onGetUsers } from "slices/users/thunk";
import { indexOf } from "lodash";

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
export const registerUser = (props: any) => async (dispatch: any) => {
  try {


    const { user, history } = props;

    const response = await axios.post('/user', {
      name: user.name,
      lastname: user.lastname,
      phone: user.phone,
      email: user.email,
      password: user.password
    })


    dispatch(registerUserSuccessful(response));

    history('/login');
    toast.success("Register User Successfully", { autoClose: 2000 })
    


  } catch (error) {

    if(typeof error === 'string' && error.indexOf("409") > -1){
      dispatch(registerUserFailed("User already exist."));
    }else{
      dispatch(registerUserFailed(error));
    }

  }
};

export const registerUserFull = (user: any) => async (dispatch: any) => {
  try {

    const authUser = window.localStorage.authJWTUser;

    const response = await axios.post('/user/create', {
      name: user.name,
      lastname: user.lastname,
      document: user.document,
      documenttype: user.documenttype,
      nationality: user.nationality,
      phone: user.phone,
      email: user.email,
      address: user.address,
      password: user.password
    }, {
      headers: {
        Authorization: `Bearer ${authUser}`
      }
    });

    dispatch(registerUserSuccessful(response));
    dispatch(onGetUsers());


  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange("");
    return response;
  } catch (error) {
    return error;
  }
};