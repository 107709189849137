import React, { useState, useRef, useMemo } from "react";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, UncontrolledTooltip } from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { projectAssignedTo } from "../../../common/data";

import { requirement_data } from "../../../common/data/requirements";


import JoditEditor from 'jodit-react';
import { useTranslation } from "react-i18next";

const FormDetail = (props: any) => {

    const { validation } = props;


    const translate = useTranslation();

    const editor = useRef(null);
    const [content, setContent] = useState('');

    return (

        <>
            <input type="hidden" className="form-control" id="formAction" name="formAction" defaultValue="add" />
            <input type="hidden" className="form-control" id="project-id-input" />
            <div className="mb-3">
                <Label htmlFor="requirement-input">{translate.t("Requirement")}</Label>

                <select className="form-control"
                    id="requirement"
                    name="requirement"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.requirement || ""}
                >
                    <option>Select</option>
                    <option value={"Apostille"}>{translate.t("Apostille")}</option>
                    <option disabled>{translate.t("Administration of oaths and declarations")}</option>
                    <option disabled>{translate.t("Attesting signatures and execution of documents")}</option>
                    <option disabled>{translate.t("Certification of academic documents")}</option>
                    <option disabled>{translate.t("Change of name documents")}</option>
                    <option disabled>{translate.t("Consular Legalisation Services")}</option>
                    <option disabled>{translate.t("Documents for working abroad")}</option>
                    <option disabled>{translate.t("International affidavits")}</option>
                    <option disabled>{translate.t("Mobile Notary Services")}</option>
                    <option disabled>{translate.t("Notarisation")}</option>
                    <option disabled>{translate.t("Notarised copies of identification and other documents")}</option>
                    <option disabled>{translate.t("Powers of attorney")}</option>
                    <option disabled>{translate.t("Travel consents")}</option>

                </select>


                {validation.touched.requirement && validation.errors.requirement ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.requirement}</FormFeedback>
                ) : null}

            </div>

            {/* {validation.values?.projectname && */}
            <div className="mb-3">
                <Label htmlFor="projectname-input">{translate.t("Required Documents")}</Label>
                <p>
                    <ul>
                        {requirement_data.find(item => item.requirement == "Apostille")?.requiredocuments
                            .map(item => <li>{item}</li>)}
                    </ul>
                </p>
            </div>
            {/* } */}


            <div className="mb-3">
                <Label htmlFor="projectdesc-input">{translate.t("Requirement Overview")}</Label>
                <JoditEditor
                    ref={editor}
                    // value={content}
                    value={validation.values.message}
                    config={{
                        readonly: false,
                        height: 400,
                        tabIndex: 1,
                        buttons: [
                            'bold',
                            'strikethrough',
                            'underline',
                            'italic', '|',
                            'ul',
                            'ol', '|',
                            'outdent', 'indent', '|',
                            'font',
                            'fontsize',
                            'brush',
                            'paragraph', '|',
                            'table',
                            'link', '|',
                            'align', 'undo', 'redo', '|',
                            'hr',
                            'eraser',
                            'copyformat', '|',
                            'fullsize'

                        ],

                    }}
                    // tabIndex={""} // tabIndex of textarea
                    // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onBlur={newContent => validation.setFieldValue("message", newContent)} // preferred to use only this option to update the content for performance reasons
                    // onChange={newContent => validation.setFieldValue("message", newContent)}
                />

                {validation.touched.message && validation.errors.message ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.message}</FormFeedback>
                ) : null}
            </div>

        </>





    );
};

export default FormDetail;
