import React, { useCallback, useState } from "react"


import { OverviewTeamMember } from "../../../common/data";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';


import {
  Table,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback
} from "reactstrap";

const TeamMembers = ({ advisors, validation }) => {

  const translate = useTranslation();

  const [assignedto, setAssignedto] = useState("");

  const onAssignUser = (e) => {
    e.preventDefault();

    // console.log(assignedto)

    let assignToAdvisors = (validation.values.assignedto || []);
    let advisor = advisors.find(item => String(item.id).toString() === assignedto);

    validation.setFieldValue("assignedto", [...assignToAdvisors, {
      ...advisor,
      userid: advisor.id,
      user: advisor
    }]);

    // setAssignedto("")
  }

  const onRemoveUser = (id) => {
    let assignToAdvisors = (validation.values.assignedto || [])
    .filter(item => item.userid != id);
    validation.setFieldValue("assignedto", assignToAdvisors);

    setAssignedto("")
  }

  const assignedIds = (validation.values.assignedto || []).map(item => item.user?.id || null)

  const _advisors = advisors.filter(item => assignedIds.indexOf(item.id) === -1)


  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{translate.t("Assigned to")}</CardTitle>


        <Row className="row row-cols-lg-auto g-1 align-items-center">
          <Col xs={12} className="flex-grow-1">
            <label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</label>
            <select className="form-select" name="select"
              value={assignedto}
              onChange={(e) => setAssignedto(e.currentTarget.value)}
              onBlur={(e) => setAssignedto(e.currentTarget.value)} >

              <option value="">Choose...</option>
              {_advisors.map(item => <option value={item.id}>{`${item.name} ${item.lastname}`}</option>)}

            </select>
            {
              validation.errors.assignedto && validation.touched.assignedto ? (
                <span className="text-danger">{validation.errors.assignedto}</span>
              ) : null
            }
          </Col>

          <Col xs={12}>
            <button onClick={onAssignUser} className="btn btn-primary w-md">{translate.t("Assign")}</button>
          </Col>
        </Row>



        <div className="table-responsive mt-4">
          <Table className="table align-middle table-nowrap">
            {(validation.values.assignedto || [])?.map((item: any, key: number) => (
              <tbody key={key}>
                <tr>

                  <td style={{ width: "50px" }}>
                    {item.img ?
                      <img src={item.img} className="rounded-circle avatar-xs" alt="" />
                      :
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {String((item.user.name || "").charAt(0)).toUpperCase()}
                        </span>
                      </div>
                    }
                  </td>
                  <td><h5 className="font-size-14 m-0"><span className="text-dark">{`${item.user.name} ${item.user.lastname}`}</span></h5></td>
                  <td>
                    <div>
                      <span className="badge bg-primary-subtle text-primary font-size-11 me-1">{translate.t(item.user.role)}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span onClick={() => onRemoveUser(item.user.id)}>
                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />Delete
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}


export default TeamMembers
