import { createSlice } from "@reduxjs/toolkit";

import { downloadFileUser } from "./thunk";

export const initialState = {
    error:"",
    loading: false,
    filename:""
};

const filesSlice = createSlice({
    name: 'filesSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
        builder.addCase(downloadFileUser.pending, (state: any, action: any) => {
            state.loading = true;
            state.error = "";
            state.filename = action.meta.arg;
        });

        builder.addCase(downloadFileUser.fulfilled, (state: any, action: any) => {
            state.loading = false;
            state.error = "";
            state.filename = "";
        });

        

        builder.addCase(downloadFileUser.rejected, (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload ? action.payload?.error : null;
            state.filename = "";
            
        });

    }
})

export default filesSlice.reducer;