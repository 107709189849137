import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import axios from "axios";



export const getUsers = createAsyncThunk("users/getUsers", async () => {

    try {
        const authUser = window.localStorage.authJWTUser;

        if (!authUser)
            return;

        const response = await axios.get('/user', {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });
        return response;


        // console.log(response)

        // const _response =  getUsersApi();
    } catch (error) {
        return error;
    }
});

export const getAdvisors = createAsyncThunk("users/advisors", async () => {

    try {
        const authUser = window.localStorage.authJWTUser;

        const response = await axios.get('/user/advisors', {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        // console.log(response)

        // const _response =  getUsersApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getUserById = createAsyncThunk("users/getUserById", async (requirementId: any) => {
    try {

        const authJWTUser = window.localStorage.authJWTUser;

        const response = await axios.get(`/user/${requirementId}`, {
            headers: {
                Authorization: `Bearer ${authJWTUser}`
            }
        });

        return response;
    } catch (error) {
        return error;
    }
})

export const getLoginUserById = createAsyncThunk("users/getLoginUserById", async (props: any, thnkApy) => {
    try {

        const { history } = props;

        let user = JSON.parse(window.localStorage.authUser);
        if (user && user.id) {
            thnkApy.dispatch(getUserById(user.id))
        } else {
            history("/dashboard")
        }

    } catch (error) {
        return error;
    }
})



export const updateUser = createAsyncThunk("users/updateUser", async (user: any, thunkAPI) => {
    try {
        // const response = updateUserApi(customer);


        const authUser = window.localStorage.authJWTUser;

        const response = await axios.put('/user', {
            id: user.id,
            name: user.name,
            lastname: user.lastname,
            document: user.document,
            documenttype: user.documenttype,
            nationality: user.nationality,
            phone: user.phone,
            email: user.email,
            address: user.address,
            password: user.password
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getUserById(user.id));
        thunkAPI.dispatch(getUsers());

        // const data = await response;
        toast.success("User Updated Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("User Updated Failed", { autoClose: 2000 });
        return error
    }
})


export const changeStatusUser = createAsyncThunk("users/changeStatusUser", async (user: any, thunkAPI) => {
    try {

        const authUser = window.localStorage.authJWTUser;

        const response = await axios.put('/user/changeStatus', {
            userid: user.id,
            status: user.status
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getUserById(user.id));
        thunkAPI.dispatch(getUsers());

        // const data = await response;
        toast.success("User Status Updated Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("User Status Updated Failed", { autoClose: 2000 });
        return error
    }
})


export const changeRoleUser = createAsyncThunk("users/changeRole", async (user: any, thunkAPI) => {
    try {

        const authUser = window.localStorage.authJWTUser;

        const response = await axios.put('/user/changeRole', {
            userid: user.id,
            role: user.role
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getUserById(user.id));
        thunkAPI.dispatch(getUsers());

        // const data = await response;
        toast.success("User Role Updated Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("User Role Updated Failed", { autoClose: 2000 });
        return error
    }
})


export const changePasswordUser = createAsyncThunk("users/changePassword", async (user: any, thunkAPI) => {
    try {

        const authUser = window.localStorage.authJWTUser;

        const response = await axios.post('/auth/profile-change-password', {
            userid: user.id,
            pass: user.password
        }, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getUserById(user.id));

        // const data = await response;
        toast.success("User Status Updated Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("User Status Updated Failed", { autoClose: 2000 });
        return error
    }
})


// export const downloadFileUser = createAsyncThunk("users/downloadFile", async (document: any, thunkAPI) => {
//     try {

//         const authUser = window.localStorage.authJWTUser;

//         const _axios = axios.create();

//         const response: any = await _axios.get(`/files/${document}`, {
//             headers: {
//                 Authorization: `Bearer ${authUser}`
//             },
//             responseType: 'blob'
//         });


//         const href = URL.createObjectURL(response.data);
//         const fileName = response.headers["x-suggested-filename"];

//         const link = window.document.createElement('a');
//         link.href = href;
//         link.setAttribute('target', "__blank");
//         // link.setAttribute('download', fileName); //or any other extension
//         window.document.body.appendChild(link);
//         link.click();

//         window.document.body.removeChild(link);
//         URL.revokeObjectURL(href);

//     } catch (error) {

//         return error
//     }
// })


// export const loadDocumentsUser = createAsyncThunk("users/loadDocumentsUser", async (user: any, thunkAPI) => {
//     try {
//         // const response = updateUserApi(customer);


//         const authUser = window.localStorage.authJWTUser;

//         const response = await axios.put('/user/documents', {
//             userid: user.userid,
//             documents: user.documents
//         }, {
//             headers: {
//                 Authorization: `Bearer ${authUser}`
//             }
//         });

//         if (user.userid) {
//             thunkAPI.dispatch(getUserById(user.userid));
//         }

//         // const data = await response;
//         toast.success("Load Documents Successfully", { autoClose: 2000 });
//         return response;
//     } catch (error) {
//         toast.error("Load Documents Failed", { autoClose: 2000 });
//         return error
//     }
// })

export const deleteUsers = createAsyncThunk("users/deleteUsers", async (userid: any, thunkAPI) => {
    try {
        // const response = deleteUsersApi(users);

        const authUser = window.localStorage.authJWTUser;
        
        const response = await axios.delete(`/user/${userid}`, {
            headers: {
                Authorization: `Bearer ${authUser}`
            }
        });

        thunkAPI.dispatch(getUsers());
        // history('/users');


        toast.success("User Deleted Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("User Deleted Failed", { autoClose: 2000 });
        return error;
    }
})



// export const addNewUser = createAsyncThunk("ecommerence/addcustomer", async (customer: any) => {
//     try {
//         const response = addNewUserApi(customer);
//         const data = await response;
//         toast.success("Contact Added Successfully", { autoClose: 2000 });
//         return data;
//     } catch (error) {
//         toast.error("Contact Added Failed", { autoClose: 2000 });
//         return error;
//     }
// })

// export const getUserProfile = createAsyncThunk("contacts/getUserProfile", async () => {
//     try {
//         const response = getUserProfileApi();
//         return response;
//     } catch (error) {
//         return error;
//     }
// })
